import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Outlet } from 'react-router-dom';
import {
  PieChart,
  EvStation,
  PermIdentity,
  Place,
  Settings,
  FileDownloadRounded,
  ContactSupport,
} from '@mui/icons-material';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Box, CssBaseline, Paper as MuiPaper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { spacing } from '@mui/system';
import jwtDecode from 'jwt-decode';
import GlobalStyle from '../components/GlobalStyle';
import Navbar from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import Footer from '../components/Footer';
import SettingsComponent from '../components/Settings';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../services/contexts';

const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up('md')} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children }) => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const decodedToken = token ? jwtDecode(token) : null;

  const pagesSection =
    decodedToken?.resource_access?.admin_oli_move?.roles.some(
      (role) => role === 'oli_move_admin'
    )
      ? [
          {
            href: '/cockpit',
            icon: PieChart,
            title: t('MenuCockPit'),
          },
          {
            href: '/standorte',
            icon: Place,
            title: t('MenuLocation'),
          },
          {
            href: '/stationen',
            icon: EvStation,
            title: t('MenuStations'),
          },
          {
            href: '/nutzer',
            icon: PermIdentity,
            title: t('LBLUser'),
          },
          {
            icon: TuneOutlinedIcon,

            title: 'Administration',
            children: [
              {
                href: '/betreiber',
                title: t('MenuOp'),
              },
              {
                href: '/rechnungen',
                title: t('AllOperatorBillsPage'),
              },
              {
                href: '/customers',
                title: t('Custumers'),
              },
              {
          href: '/rfid',
          title: t('RFIDs'),
        },
      ],
          },
          {
            href: '/downloadcenter',
            icon: FileDownloadRounded,
            title: 'Download Center',
          },
          {
            href: '/einstellungen',
            icon: Settings,
            title: t('MenuIdeas'),
          },
          {
            href: '/support',
            icon: ContactSupport,
            title: t('MenuSupport'),
          },
        ]
      : [
          {
            href: '/cockpit',
            icon: PieChart,
            title: t('MenuCockPit'),
          },
          {
            href: '/standorte',
            icon: Place,
            title: t('MenuLocation'),
          },
          {
            href: '/stationen',
            icon: EvStation,
            title: t('MenuStations'),
          },
          {
            href: '/nutzer',
            icon: PermIdentity,
            title: t('LBLUser'),
          },
          {
            icon: TuneOutlinedIcon,

            title: 'Administration',
            children: [
              {
                href: '/betreiber',
                title: t('MenuOp'),
              },
              {
                href: '/rechnungen',
                title: t('AllOperatorBillsPage'),
              },
            ],
          },
          {
            href: '/downloadcenter',
            icon: FileDownloadRounded,
            title: 'Download Center',
          },
          {
            href: '/einstellungen',
            icon: Settings,
            title: t('MenuIdeas'),
          },
          {
            href: '/support',
            icon: ContactSupport,
            title: t('MenuSupport'),
          },
        ];
  const navItems = [
    {
      pages: pagesSection,
    },
  ];

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={navItems}
          />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={navItems}
          />
        </Box>
      </Drawer>
      <AppContent>
        <Navbar onDrawerToggle={handleDrawerToggle} />
        <MainContent sx={{backgroundColor: theme.palette.background.default}} py={isLgUp ? 12 : 5} px={isLgUp ? 8 : 5}>
          {children}
          <Outlet />
        </MainContent>
        <Footer />
      </AppContent>
      <SettingsComponent />
    </Root>
  );
};

export default Dashboard;
