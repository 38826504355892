/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Button as MuiButton,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components/macro';
import { spacing } from '@mui/system';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import api from '../../../Middleware/api/api';
import { useAuth, useUser } from '../../../services/contexts';
import { Add as AddIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const ButtonMini = styled(MuiButton)(spacing);

const SmallButton = styled(ButtonMini)`
  padding: 4px;
  min-width: 0;
  background-color: transparent;
  border: 1px solid grey;
  color: grey;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;
const initialValues = {
  totalSections: 0,
  chargingStationModelOne: '',
  chargingStationModelTwo: '',
  chargingStationModelThree: '',
  chargingStationModelFour: '',
  emsId: '',
  emsModel: '',
  isEmsConnected: 'true',
  infraType: 'true',
  numOfChargingStationsOne: 0,
  numOfChargingStationsTwo: 0,
  numOfChargingStationsThree: 0,
  numOfChargingStationsFour: 0,
};

function LocationFormMultiple({ setPayload, submit, setCanNext, setSubmit }) {
  const { t } = useTranslation();
  const { token } = useAuth();
  const { user } = useUser();
  // eslint-disable-next-line no-unused-vars
  const [wallboxInfo, setWallboxInfo] = useState([]);
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  // eslint-disable-next-line no-unused-vars
  const [wallboxLoadError, setWallboxLoadError] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [displayEms, setDisplayEms] = useState('show');
  const [displayEmsModel, setDisplayEmsModel] = useState(true);
  const [displayInfra, setDisplayInfra] = useState(true);

  const [showEmsError, setShowEmsError] = useState(false);
  const [showDuplicateErrorOne, setShowDuplicateErrorOne] = useState(false);
  const [showDuplicateError, setShowDuplicateError] = useState(false);
  const [showDuplicateErrorTwo, setShowDuplicateErrorTwo] = useState(false);
  const [showDuplicateErrorThree, setShowDuplicateErrorThree] = useState(false);
  const [showDuplicateErrorFour, setShowDuplicateErrorFour] = useState(false);
  const [totalHeader, setTotalHeader] = useState(1);

  useEffect(() => {
    if (submit > 0) document.getElementById('subMultiple').click();
    setSubmit(0);
  }, [submit]);

  const getWallboxInfo = async () => {
    api.fetchWallboxTypes(token).then(
      (response) => {
        if (response.data.data == null) {
          setWallboxInfo([]);
        } else {
          setWallboxInfo(response.data.data);
        }
      },
      (requestError) => {
        setWallboxLoadError(requestError.message);
      }
    );
  };
  useEffect(() => {
    getWallboxInfo();
  }, []);

  const validationSchema = useMemo(
    () =>
      yup.object(
        displayInfra == true
          ? {
              totalSections: yup.number(),
              chargingStationModelOne:
                totalHeader > 0
                  ? yup.string().required(t('LBLReqMSG'))
                  : yup.string(),
              chargingStationModelTwo:
                totalHeader > 1
                  ? yup.string().required(t('LBLReqMSG'))
                  : yup.string(),
              chargingStationModelThree:
                totalHeader > 2
                  ? yup.string().required(t('LBLReqMSG'))
                  : yup.string(),
              chargingStationModelFour:
                totalHeader > 3
                  ? yup.string().required(t('LBLReqMSG'))
                  : yup.string(),
              emsModel: yup.string(),
              isEmsConnected: yup.string(),
              infraType: yup.string(),
              numOfChargingStationsOne:
                totalHeader > 0
                  ? yup
                      .number()
                      .min(1, t('MustBeonecs'))
                      .required(t('MustBeonecs'))
                  : yup.number(),

              numOfChargingStationsTwo:
                totalHeader > 1
                  ? yup
                      .number()
                      .min(1, t('MustBeonecs'))
                      .required(t('MustBeonecs'))
                  : yup.number(),

              numOfChargingStationsThree:
                totalHeader > 2
                  ? yup
                      .number()
                      .min(1, t('MustBeonecs'))
                      .required(t('MustBeonecs'))
                  : yup.number(),

              numOfChargingStationsFour:
                totalHeader > 3
                  ? yup
                      .number()
                      .min(1, t('MustBeonecs'))
                      .required(t('MustBeonecs'))
                  : yup.number(),
            }
          : {
              totalSections: yup.number(),
              chargingStationModelOne: yup
                .string()
                .when('numOfChargingStationsOne', {
                  is: (val) => val > 0,
                  then: (schema) =>
                    schema.required(t('LBLChargingStationModelReq')),
                  otherwise: (schema) => schema.notRequired(),
                }),
              chargingStationModelTwo: yup
                .string()
                .when('numOfChargingStationsTwo', {
                  is: (val) => val > 0,
                  then: (schema) =>
                    schema.required(t('LBLChargingStationModelReq')),
                  otherwise: (schema) => schema.notRequired(),
                }),
              chargingStationModelThree: yup
                .string()
                .when('numOfChargingStationsThree', {
                  is: (val) => val > 0,
                  then: (schema) =>
                    schema.required(t('LBLChargingStationModelReq')),
                  otherwise: (schema) => schema.notRequired(),
                }),
              chargingStationModelFour: yup
                .string()
                .when('numOfChargingStationsFour', {
                  is: (val) => val > 0,
                  then: (schema) =>
                    schema.required(t('LBLChargingStationModelReq')),
                  otherwise: (schema) => schema.notRequired(),
                }),
              emsModel: yup.string(),
              isEmsConnected: yup.string(),
              infraType: yup.string(),
              numOfChargingStationsOne: yup.number(),
              numOfChargingStationsTwo: yup.number(),
              numOfChargingStationsThree: yup.number(),
              numOfChargingStationsFour: yup.number(),
            }
      ),
    [displayEmsModel, totalHeader, displayInfra]
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setPayload({
        totalSections: totalHeader,
        chargingStationModelOne: values.chargingStationModelOne,
        chargingStationModelTwo: values.chargingStationModelTwo,
        chargingStationModelThree: values.chargingStationModelThree,
        chargingStationModelFour: values.chargingStationModelFour,
        emsId: values.emsId,
        emsModel: values.emsModel,
        isEmsConnected:
          values.isEmsConnected === 'true' && values.infraType === 'true',
        infraType: values.infraType === 'true',
        numOfChargingStationsOne: values.numOfChargingStationsOne,
        numOfChargingStationsTwo: values.numOfChargingStationsTwo,
        numOfChargingStationsThree: values.numOfChargingStationsThree,
        numOfChargingStationsFour: values.numOfChargingStationsFour,
      });
    },
  });

  useEffect(() => {
    setCanNext(
      formik.isValid &&
        (!displayEmsModel || !!formik.values.emsModel || !displayInfra) &&
        !showDuplicateErrorOne &&
        !showDuplicateErrorTwo &&
        !showDuplicateErrorThree &&
        !showDuplicateErrorFour
    );
  }, [formik.isValid, formik.values.emsModel]);
  useEffect(() => {
    if (
      formik.values.chargingStationModelOne != '' &&
      (formik.values.chargingStationModelOne ==
        formik.values.chargingStationModelTwo ||
        formik.values.chargingStationModelOne ==
          formik.values.chargingStationModelThree ||
        formik.values.chargingStationModelOne ==
          formik.values.chargingStationModelFour)
    ) {
      setShowDuplicateError(true);
      setShowDuplicateErrorOne(true);
    } else {
      setShowDuplicateErrorOne(false);
    }
  }, [formik.values.chargingStationModelOne]);
  useEffect(() => {
    if (formik.values.infraType == true) {
      setDisplayInfra(true);
    } else {
      setDisplayInfra(false);
    }
  }, [formik.values.infraType]);
  useEffect(() => {
    if (
      formik.values.chargingStationModelTwo != '' &&
      (formik.values.chargingStationModelTwo ==
        formik.values.chargingStationModelOne ||
        formik.values.chargingStationModelTwo ==
          formik.values.chargingStationModelThree ||
        formik.values.chargingStationModelTwo ==
          formik.values.chargingStationModelFour)
    ) {
      setShowDuplicateError(true);
      setShowDuplicateErrorTwo(true);
    } else {
      setShowDuplicateErrorTwo(false);
    }
  }, [formik.values.chargingStationModelTwo]);
  useEffect(() => {
    if (
      formik.values.chargingStationModelThree != '' &&
      (formik.values.chargingStationModelThree ==
        formik.values.chargingStationModelOne ||
        formik.values.chargingStationModelThree ==
          formik.values.chargingStationModelTwo ||
        formik.values.chargingStationModelThree ==
          formik.values.chargingStationModelFour)
    ) {
      setShowDuplicateError(true);
      setShowDuplicateErrorThree(true);
    } else {
      setShowDuplicateErrorThree(false);
    }
  }, [formik.values.chargingStationModelThree]);
  useEffect(() => {
    if (
      formik.values.chargingStationModelFour != '' &&
      (formik.values.chargingStationModelFour ==
        formik.values.chargingStationModelOne ||
        formik.values.chargingStationModelFour ==
          formik.values.chargingStationModelTwo ||
        formik.values.chargingStationModelFour ==
          formik.values.chargingStationModelThree)
    ) {
      setShowDuplicateError(true);
      setShowDuplicateErrorFour(true);
    } else {
      setShowDuplicateErrorFour(false);
    }
  }, [formik.values.chargingStationModelFour]);
  return (
    <>
      <form noValidate onSubmit={formik.handleSubmit}>
        <Grid item xs>
          <FormControl>
            <FormLabel
              id="demo-radio-buttons-group-labe1l"
              sx={{ fontSize: 16 }}
            >
              {t('chargingInfra')}
            </FormLabel>
            <RadioGroup
              value={formik.values.infraType}
              row
              aria-labelledby="locationNr"
              name="infraType"
              onChange={(event, value) => {
                setDisplayInfra(value);
                formik.handleChange(event);
              }}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label={t('yes')}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label={t('no')}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container md={12} spacing={2} sx={{ pt: 2 }}>
          {formik.values.infraType == 'true' ? (
            <>
              <Grid container spacing={2} sx={{ width: '100%' }}>
                <Grid item md={6}>
                  <TextField
                    margin="dense"
                    id="locationNr"
                    type="number"
                    inputProps={{ min: 0, max: 100 }}
                    value={formik.values.numOfChargingStationsOne}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.numOfChargingStationsOne &&
                      Boolean(formik.errors.numOfChargingStationsOne)
                    }
                    helperText={
                      formik.touched.numOfChargingStations &&
                      formik.errors.numOfChargingStationsOne
                    }
                    name="numOfChargingStationsOne"
                    label={t('LBLNumberOfCharginStations')}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid container spacing={2} paddingTop={2} md={6}>
                  <Grid item md={9} marginLeft={2}>
                    <FormControl
                      fullWidth
                      sx={{ marginBottom: 2, marginTop: 2 }}
                    >
                      <TextField
                        select
                        id="Ladestation-model"
                        label={t('CSModel')}
                        required
                        value={formik.values.chargingStationModelOne}
                        onChange={formik.handleChange}
                        error={
                          (formik.touched.chargingStationModelOne &&
                            Boolean(formik.errors.chargingStationModelOne)) ||
                          showDuplicateErrorOne
                        }
                        helperText={
                          formik.touched.chargingStationModelOne &&
                          formik.errors.chargingStationModelOne
                        }
                        name="chargingStationModelOne"
                        sx={{ mt: 0 }}
                        disabled={
                          formik?.values?.numOfChargingStationsOne === 0
                        }
                      >
                        {wallboxInfo.map((model, index) => (
                          <MenuItem key={index} value={model}>
                            {model}
                          </MenuItem>
                        ))}
                      </TextField>
                    </FormControl>
                    <FormHelperText
                      error
                      sx={{ display: showDuplicateErrorOne ? 'show' : 'none' }}
                    >
                      {t('eachcharging')}
                    </FormHelperText>
                  </Grid>
                  {totalHeader == 1 ? (
                    <Grid item marginTop={3} md={1}>
                      <SmallButton
                        size="medium"
                        mr={2}
                        onClick={() => {
                          setTotalHeader(2);
                        }}
                      >
                        <AddIcon />
                      </SmallButton>
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>
              {totalHeader > 1 ? (
                <Grid container spacing={2} sx={{ width: '100%' }}>
                  <Grid item md={6}>
                    <TextField
                      margin="dense"
                      id="locationNr"
                      type="number"
                      inputProps={{ min: 0, max: 100 }}
                      value={formik.values.numOfChargingStationsTwo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.numOfChargingStationsTwo &&
                        Boolean(formik.errors.numOfChargingStationsTwo)
                      }
                      helperText={
                        formik.touched.numOfChargingStationsTwo &&
                        formik.errors.numOfChargingStationsTwo
                      }
                      name="numOfChargingStationsTwo"
                      label={t('LBLNumberOfCharginStations')}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid container spacing={2} paddingTop={2} md={6}>
                    <Grid item md={9} marginLeft={2}>
                      <FormControl
                        fullWidth
                        sx={{ marginBottom: 2, marginTop: 2 }}
                      >
                        <TextField
                          select
                          id="Ladestation-model"
                          label={t('CSModel')}
                          required
                          value={formik.values.chargingStationModelTwo}
                          onChange={formik.handleChange}
                          error={
                            (formik.touched.chargingStationModelTwo &&
                              Boolean(formik.errors.chargingStationModelTwo)) ||
                            showDuplicateErrorTwo
                          }
                          helperText={
                            formik.touched.chargingStationModelTwo &&
                            formik.errors.chargingStationModelTwo
                          }
                          name="chargingStationModelTwo"
                          sx={{ mt: 0 }}
                          disabled={
                            formik?.values?.numOfChargingStationsTwo === 0
                          }
                        >
                          {wallboxInfo.map((model, index) => (
                            <MenuItem key={index} value={model}>
                              {model}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                      <FormHelperText
                        error
                        sx={{
                          display: showDuplicateErrorTwo ? 'show' : 'none',
                        }}
                      >
                        {t('eachcharging')}
                      </FormHelperText>
                    </Grid>
                    {totalHeader == 2 ? (
                      <Grid item marginLeft={2} marginTop={2} md={1}>
                        <SmallButton
                          size="medium"
                          mr={2}
                          onClick={() => {
                            formik.values.numOfChargingStationsTwo = 0;
                            setTotalHeader(1);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </SmallButton>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                    {totalHeader == 2 ? (
                      <Grid item marginLeft={2} marginTop={2} md={1}>
                        <SmallButton
                          size="medium"
                          mr={2}
                          onClick={() => {
                            setTotalHeader(3);
                          }}
                        >
                          <AddIcon />
                        </SmallButton>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}
              {totalHeader > 2 ? (
                <Grid container spacing={2} sx={{ width: '100%' }}>
                  <Grid item md={6}>
                    <TextField
                      margin="dense"
                      id="locationNr"
                      type="number"
                      inputProps={{ min: 0, max: 100 }}
                      value={formik.values.numOfChargingStationsThree}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.numOfChargingStationsThree &&
                        Boolean(formik.errors.numOfChargingStationsThree)
                      }
                      helperText={
                        formik.touched.numOfChargingStationsThree &&
                        formik.errors.numOfChargingStationsThree
                      }
                      name="numOfChargingStationsThree"
                      label={t('LBLNumberOfCharginStations')}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid container spacing={2} paddingTop={2} md={6}>
                    <Grid item md={9} marginLeft={2}>
                      <FormControl
                        fullWidth
                        sx={{ marginBottom: 2, marginTop: 2 }}
                      >
                        <TextField
                          select
                          id="Ladestation-model"
                          label={t('CSModel')}
                          required
                          value={formik.values.chargingStationModelThree}
                          onChange={formik.handleChange}
                          error={
                            (formik.touched.chargingStationModelTwo &&
                              Boolean(
                                formik.errors.chargingStationModelThree
                              )) ||
                            showDuplicateErrorThree
                          }
                          helperText={
                            formik.touched.chargingStationModelThree &&
                            formik.errors.chargingStationModelThree
                          }
                          name="chargingStationModelThree"
                          disabled={
                            formik?.values?.numOfChargingStationsThree === 0
                          }
                          sx={{ mt: 0 }}
                        >
                          {wallboxInfo.map((model, index) => (
                            <MenuItem key={index} value={model}>
                              {model}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                      <FormHelperText
                        error
                        sx={{
                          display: showDuplicateErrorThree ? 'show' : 'none',
                        }}
                      >
                        {t('eachcharging')}
                      </FormHelperText>
                    </Grid>
                    {totalHeader == 3 ? (
                      <Grid item marginLeft={2} marginTop={2} md={1}>
                        <SmallButton
                          size="medium"
                          mr={2}
                          onClick={() => {
                            formik.values.numOfChargingStationsThree = 0;
                            setTotalHeader(2);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </SmallButton>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                    {totalHeader == 3 ? (
                      <Grid item marginLeft={2} marginTop={2} md={1}>
                        <SmallButton
                          size="medium"
                          mr={2}
                          onClick={() => {
                            setTotalHeader(4);
                          }}
                        >
                          <AddIcon />
                        </SmallButton>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}
              {totalHeader > 3 ? (
                <Grid container spacing={2} sx={{ width: '100%' }}>
                  <Grid item md={6}>
                    <TextField
                      margin="dense"
                      id="locationNr"
                      type="number"
                      inputProps={{ min: 0, max: 100 }}
                      value={formik.values.numOfChargingStationsFour}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.numOfChargingStationsThree &&
                        Boolean(formik.errors.numOfChargingStationsFour)
                      }
                      helperText={
                        formik.touched.numOfChargingStationsFour &&
                        formik.errors.numOfChargingStationsFour
                      }
                      name="numOfChargingStationsFour"
                      label={t('LBLNumberOfCharginStations')}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid container spacing={2} paddingTop={2} md={6}>
                    <Grid item md={9} marginLeft={2}>
                      <FormControl
                        fullWidth
                        sx={{ marginBottom: 2, marginTop: 2 }}
                      >
                        <TextField
                          select
                          id="Ladestation-model"
                          label={t('CSModel')}
                          required
                          value={formik.values.chargingStationModelFour}
                          onChange={formik.handleChange}
                          error={
                            (formik.touched.chargingStationModelFour &&
                              Boolean(
                                formik.errors.chargingStationModelFour
                              )) ||
                            showDuplicateErrorFour
                          }
                          helperText={
                            formik.touched.chargingStationModelFour &&
                            formik.errors.chargingStationModelFour
                          }
                          name="chargingStationModelFour"
                          sx={{ mt: 0 }}
                          disabled={
                            formik?.values?.numOfChargingStationsFour === 0
                          }
                        >
                          {wallboxInfo.map((model, index) => (
                            <MenuItem key={index} value={model}>
                              {model}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                      <FormHelperText
                        error
                        sx={{
                          display: showDuplicateErrorFour ? 'show' : 'none',
                        }}
                      >
                        {t('eachcharging')}
                      </FormHelperText>
                    </Grid>

                    {totalHeader == 4 ? (
                      <Grid item marginLeft={2} marginTop={2} md={1}>
                        <SmallButton
                          size="medium"
                          mr={2}
                          onClick={() => {
                            formik.values.numOfChargingStationsFour = 0;
                            setTotalHeader(3);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </SmallButton>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}

              <Grid
                container
                spacing={2}
                sx={{ display: displayEms, width: '100%' }}
              >
                <Grid item xs>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-labe1l"
                      sx={{ fontSize: 16 }}
                    >
                      {t('connectEms')}
                    </FormLabel>
                    <RadioGroup
                      value={formik.values.isEmsConnected}
                      row
                      aria-labelledby="locationNr"
                      name="isEmsConnected"
                      onChange={(event, value) => {
                        setDisplayEmsModel(value === 'true');
                        formik.handleChange(event);
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={t('yes')}
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={t('no')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs>
                  {displayEmsModel && (
                    <>
                      <TextField
                        select
                        fullWidth
                        id="ems-model"
                        label="EMS Modell"
                        disabled={!displayEmsModel}
                        value={formik.values.emsModel}
                        onChange={formik.handleChange}
                        error={
                          displayEmsModel &&
                          !formik.values.emsModel &&
                          showEmsError
                        }
                        helperText={
                          formik.touched.emsModel && formik.errors.emsModel
                        }
                        name="emsModel"
                      >
                        <MenuItem value={'Smart1 EMS'}>Smart1 EMS</MenuItem>
                        <MenuItem value={'CFOS Charging Manager'}>
                          CFOS Charging Manager
                        </MenuItem>
                      </TextField>
                      <FormHelperText
                        error
                        sx={{
                          display:
                            displayEmsModel &&
                            !formik.values.emsModel &&
                            showEmsError
                              ? 'show'
                              : 'none',
                        }}
                      >
                        {t('emsModelReq')}
                      </FormHelperText>
                    </>
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <div></div>
          )}
        </Grid>

        <Button
          sx={{ display: 'none' }}
          id="subMultiple"
          onClick={() => setShowEmsError(true)}
          type="submit"
        ></Button>
      </form>
    </>
  );
}

export default LocationFormMultiple;
