import React, { useEffect, useMemo } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import { useTranslation } from 'react-i18next';
function UpdateOperatorERP({
  operatorInfo,
  setPayload,
  submit,
  setCanNext,
  setSubmit,
}) {
  const { t } = useTranslation();
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        clientNumber: yup.string().required(t('clNreq')),
        netLedgerAccountNr: yup.string().required(t('ntlgreq')),
        paymentMethod: yup.string().required(t('paymentMethodreq')),
      }),
    []
  );
  useEffect(() => {
    if (submit > 0) document.getElementById('subErstellung').click();
    setSubmit(0);
  }, [submit]);
  const formik = useFormik({
    initialValues: {
      clientNumber: operatorInfo?.clientNumber || '',
      netLedgerAccountNr: operatorInfo?.netLedgerAccountNr || '',
      taxLedgerAccountNr: operatorInfo?.taxLedgerAccountNr || '',
      paymentMethod: operatorInfo?.paymentMethod || '',
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setPayload({
        cpoId: operatorInfo?.cpoId,
        clientNumber: values?.clientNumber || '',
        netLedgerAccountNr: values?.netLedgerAccountNr || '',
        taxLedgerAccountNr: values?.taxLedgerAccountNr || '',
        paymentMethod: values?.paymentMethod || '',
      });
    },
  });
  useEffect(() => {
    setCanNext(formik.isValid && formik.touched);
  }, [formik]);

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                margin="dense"
                id="clientNumber"
                label={t('clientNo')}
                variant="outlined"
                required
                fullWidth
                name={'clientNumber'}
                my={2}
                value={formik.values.clientNumber}
                onChange={formik.handleChange}
                error={
                  formik.touched.clientNumber &&
                  Boolean(formik.errors.clientNumber)
                }
                helperText={
                  formik.touched.clientNumber && formik.errors.clientNumber
                }
              />
            </Grid>
          </Grid>
          <br></br>
          <br></br>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="netLedgerAccountNr"
                label={t('glNum')}
                variant="outlined"
                required
                fullWidth
                name={'netLedgerAccountNr'}
                my={2}
                value={formik.values.netLedgerAccountNr}
                onChange={formik.handleChange}
                error={
                  formik.touched.netLedgerAccountNr &&
                  Boolean(formik.errors.netLedgerAccountNr)
                }
                helperText={
                  formik.touched.netLedgerAccountNr &&
                  formik.errors.netLedgerAccountNr
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="taxLedgerAccountNr"
                label={t('glNumVat')}
                variant="outlined"
                required
                fullWidth
                name={'taxLedgerAccountNr'}
                my={2}
                value={formik.values.taxLedgerAccountNr}
                onChange={formik.handleChange}
                error={
                  formik.touched.taxLedgerAccountNr &&
                  Boolean(formik.errors.taxLedgerAccountNr)
                }
                helperText={
                  formik.touched.taxLedgerAccountNr &&
                  formik.errors.taxLedgerAccountNr
                }
              />
            </Grid>
          </Grid>
          <br></br>
          <br></br>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                margin="dense"
                id="paymentMethod"
                label={t('LBLpaymentMethod')}
                variant="outlined"
                required
                fullWidth
                name={'paymentMethod'}
                my={2}
                value={
                  formik.values.paymentMethod === 'BANK_TRANSFER'
                    ? t('rechnung')
                    : formik.values.paymentMethod === 'STRIPE_SEPA'
                      ? t('sepaStripemode')
                      : formik.values.paymentMethod === 'SEPA'
                        ? t('LBLsepamode')
                        : formik.values.paymentMethod
                }
                disabled={true}
                error={
                  formik.touched.paymentMethod &&
                  Boolean(formik.errors.paymentMethod)
                }
                helperText={
                  formik.touched.paymentMethod && formik.errors.paymentMethod
                }
              />
            </Grid>
          </Grid>

          <br></br>

          {/**
           * this button is hidden and used to trigger submit from outside of the component
           */}
          <Button
            sx={{ display: 'none' }}
            id="subErstellung"
            type="submit"
          ></Button>
        </form>
      </FormikProvider>
    </>
  );
}
export default UpdateOperatorERP;
