import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import shadows from '@mui/material/styles/shadows';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MIN_PAGE_SIZE, PAGE_SIZE } from '../../services/utils/units-constants';

export const statusMappingBackendToFrontend = {
  inactive: 'Inaktiv',
  connected_unavailable: {
    noParkingLot: 'Verknüpft (Parkplatz hinterlegen)',
    withParkingLot: 'Standby',
  },
  idle: 'Aktiv',
  occupied_charging: 'Aktiv',
  occupied_uncharged: 'Aktiv',
  malfunction: 'Störung',
  connected: 'Aktiv',
  stand_by: 'Standby',
  offline: 'Störung',
  suspended: 'Aktiv',
  suspended_evse: 'Aktiv',
};
const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MuiPaper: {
        variants: [
          {
            props: {
              variant: 'elevation',
            },
            style: {
              boxShadow: shadows[3],
            },
          },
        ],
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: 300,
            boxShadow: shadows[3],
            backgroundColor: appTheme.palette.background.paper,
          },
          title: {
            lineHeight: 3,
          },
          header: {
            marginBottom: 0,
          },
          gridListTile: {
            styleOverrides: {
              paddingLeft: '25px',
              marginLeft: '25px',
            },
            sx: {
              pl: 28,
            },
          },

          reset: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: 4,
          },
          resetLink: {
            button: {
              variants: [
                {
                  props: {
                    variant: 'outlined',
                  },
                },
              ],
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'filled',
          margin: 'dense',
          size: 'medium',
          fullWidth: false,
          sx: {
            ml: 2,
            width: '230px',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          margin: 'dense',
        },
      },
    },
  });

const CustomChip = ({ label, onDelete }) => {
  return (
    <Chip
      sx={{ mr: 2 }}
      size="small"
      variant="outlined"
      color="secondary"
      label={label}
      onDelete={onDelete}
    />
  );
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

/**
 * Custom DataTable component
 * @param {import('../../../DataTable').DataTableProps} props
 */
const DataTable = ({ data, title, options, components, columns, getData }) => {
  const { t, i18n } = useTranslation();
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [currentPageVal, setCurrentPage] = useState(options?.currentPage);
  const { totalRecords } = options;
  useEffect(() => {
    getData(pageSize, currentPageVal);
  }, [currentPageVal, pageSize]);

  return (
    <>
      <ThemeProvider
        theme={(appTheme) => ({
          ...appTheme,
          components: {
            ...appTheme.components,
            ...dataTableTheme(appTheme).components,
          },
        })}
      >
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          components={{
            ...components,
            TableFilterList: CustomFilterList,
            TableToolbarSelect: ({ selectedRows }) => {
              const numOfSelected = selectedRows.data.length;

              return (
                <Typography
                  align="right"
                  fontWeight={600}
                  color="text.secondary"
                  fontSize={({ typography }) => typography.h6.fontSize}
                  sx={{ mx: 5, my: 4 }}
                >
                  {numOfSelected} {t('rowsSelected')}
                </Typography>
              );
            },
          }}
          options={{
            ...options,
            pagination: totalRecords > MIN_PAGE_SIZE ? true : false,
            serverSide: true,
            count: totalRecords,
            rowsPerPage: pageSize,
            rowsPerPageOptions: PAGE_SIZE,
            page: currentPageVal,
            onTableChange: (action, tableState) => {
              setPageSize(tableState.rowsPerPage);
              if (action === 'changePage') {
                setCurrentPage(tableState.page);
              } else if (action === 'changeRowsPerPage') {
                if (currentPageVal !== tableState.page) {
                  setCurrentPage(tableState.page);
                }
              } else if (action === 'propsUpdate') {
                setCurrentPage(options?.currentPage);
              } else {
                // console.log('action not handled.');
              }
            },
            textLabels: {
              ...options.textLabels,
              body: {
                noMatch: t('noinfoFound'),
                toolTip: 'Sortieren',
              },
              pagination: {
                rowsPerPage: t('LBLRowsPerPage'),
                displayRows: t('LBLFrom'),
              },
            },
            filter: options.filter || false,
            search: options.search || false,
            download: options.download || false,
            print: options.print || false,
            viewColumns: options.viewColumns || false,
            filterType: options.filterType || 'multiselect',
            selectableRows: options.selectableRows || 'multiple',
            responsive: options.responsive || 'standard',
            resizableColumns: options.resizableColumns || false,
            scrollX: options.scrollX || true,
            overflowX: options.overflowX || 'auto',
            overflowY: options.overflowY || 'auto',
          }}
        />
      </ThemeProvider>
    </>
  );
};

DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  title: PropTypes.string.isRequired,
};

DataTable.defaultProps = {
  data: [],
  columns: [],
};

export default DataTable;
