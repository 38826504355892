import React, { useState } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const RFIDForm = ({ isOpen, handleClose, setPayload }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { t } = useTranslation();
  const steps = [t('enterRFIDinfo'), t('confirmInfo')];

  // Initial form values
  let initialValues = {
    fields: [{ serialNumber: '', rfidTag: '' }],
  };

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    fields: Yup.array().of(
      Yup.object().shape({
        serialNumber: Yup.string().required(t('LBLRFIDSerialNr')),
        rfidTag: Yup.string().required(t('LBLRFIDTag')),
      })
    ),
  });

  // Function to handle form submission
  const handleSubmit = (values) => {
    console.log(activeStep === steps.length - 1);
    if (activeStep === steps.length - 1) {
      initialValues = {
        fields: [{ serialNumber: '', rfidTag: '' }],
      };
      console.log('Form Data:', values?.fields);
      setActiveStep(0);
      setPayload(values?.fields);
      handleClose();
    } else {
      handleNext();
      console.log('Form Data:', values?.fields);
    }
  };

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperProps={{
        sx: {
          py: 3,
          px: 3,
          width: '100%',
          maxWidth: { xs: '100%', sm: '600px' }, // Adjusts for mobile and desktop
          margin: 'auto',
        },
      }}
    >
      <DialogTitle>
        {t('LBLCreateRFID')}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ textAlign: 'center', py: { xs: 4, sm: 8 } }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, handleChange, handleBlur }) => (
            <Form>
              <Stepper
                activeStep={activeStep}
                sx={{
                  marginBottom: 2,
                  flexDirection: { xs: 'column', sm: 'row' },
                  alignItems: { xs: 'flex-start', sm: 'center' },
                }}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStep === 0 && (
                <Box mx={{ xs: 1, sm: 5 }}>
                  <FieldArray name="fields">
                    {({ push, remove }) => (
                      <Box spacing={2}>
                        {values.fields.map((field, index) => (
                          <Box sx={{ display: { sm: 'flex' } }} key={index}>
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label={`${t('enterRFIDSerialNr')} ${index + 1}`}
                                variant="outlined"
                                name={`fields[${index}].serialNumber`}
                                value={field.serialNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.fields?.[index]?.serialNumber &&
                                  !!errors.fields?.[index]?.serialNumber
                                }
                                helperText={
                                  touched.fields?.[index]?.serialNumber &&
                                  errors.fields?.[index]?.serialNumber
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                              <TextField
                                fullWidth
                                label={`${t('enterRFIDTag')} ${index + 1}`}
                                variant="outlined"
                                name={`fields[${index}].rfidTag`}
                                value={field.rfidTag}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.fields?.[index]?.rfidTag &&
                                  !!errors.fields?.[index]?.rfidTag
                                }
                                helperText={
                                  touched.fields?.[index]?.rfidTag &&
                                  errors.fields?.[index]?.rfidTag
                                }
                                sx={{
                                  mt: { xs: 2, sm: 4 },
                                  ml: { xs: 0, sm: 5 },
                                }}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              sm={2}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              sx={{
                                mt: { xs: 2, sm: 0 },
                                ml: { xs: 0, sm: 5 },
                              }}
                            >
                              {index === values.fields.length - 1 && (
                                <IconButton
                                  color="primary"
                                  onClick={() =>
                                    push({ serialNumber: '', rfidTag: '' })
                                  }
                                >
                                  <AddCircleOutline />
                                </IconButton>
                              )}
                              {values.fields.length > 1 && (
                                <IconButton
                                  color="grey.700"
                                  onClick={() => remove(index)}
                                >
                                  <RemoveCircleOutline />
                                </IconButton>
                              )}
                            </Grid>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </FieldArray>
                </Box>
              )}

              {activeStep === 1 && (
                <Box mt={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    my={4}
                    mx={5}
                  >
                    <Typography variant="body1" fontWeight={'600'}>
                      {t('enterRFIDSerialNr')}
                    </Typography>
                    <Typography
                      variant="body1"
                      fontWeight={'600'}
                      align="right"
                    >
                      {t('enterRFIDTag')}
                    </Typography>
                  </Stack>
                  {values.fields.map((field, index) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      my={4}
                      mx={5}
                    >
                      <Typography variant="body1">
                        {field?.serialNumber}
                      </Typography>
                      <Typography variant="body1" align="right">
                        {field?.rfidTag}
                      </Typography>
                    </Stack>
                  ))}
                </Box>
              )}

              <Box mt={2} display="flex" justifyContent="flex-end">
                <Button variant="contained" color="primary" type="submit">
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>

                {activeStep > 0 && (
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={handleBack}
                    sx={{ ml: 2, mt: { xs: 2, sm: 0 } }}
                  >
                    Back
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default RFIDForm;
