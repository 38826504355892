import React, { useEffect, useState, useMemo } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useFormik, Field, FormikProvider } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { Add as AddIcon } from '@mui/icons-material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import styled from 'styled-components/macro';
import { Button as MuiButton } from '@mui/material';
import { spacing } from '@mui/system';
import { RadioGroup } from 'formik-mui';
import Radio from '@mui/material/Radio';
import { useTranslation } from 'react-i18next';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { validators } from '../../../services/utils/validators';
const ButtonIcon = styled(MuiButton)(spacing);
const SmallButton = styled(ButtonIcon)`
  padding: 4px;
  min-width: 0;
  margin-top: 10px;
  color: grey;
  border: 1px solid grey;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`;
function OperatorForm({ setPayload, submit, setCanNext, setSubmit }) {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalHeader, setTotalHeader] = useState(1);
  const [totalFooter, setTotalFooter] = useState(1);
  const [paymentSepa, setPaymentSepa] = useState(false);
  const [paymentBank, setPaymentBank] = useState(false);
  const [billingType, setBillingType] = useState(null);
  const [showBillingTypeReq, setShowBillingTypeReq] = useState(false);
  const [addCpoPaymentDetails, setAddCpoPaymentDetails] = useState(true);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        contactPersonName: yup.string().required(t('Namereq')),
        contactPersonPosition: yup.string().required(t('PositionReq')),
        contactPersonPhone: yup
          .number()
          .typeError(t('NumericOnly'))
          .required(t('TeleReq')),
        contactPersonEmail: yup
          .string()
          .matches(validators.EMAIL, t('wrongEmail'))
          .email(t('wrongEmail'))
          .required(t('emailReq')),
        moneyCollectionGapInDays: addCpoPaymentDetails
          ? yup.number().typeError(t('NumericOnly')).required(t('reqField'))
          : yup.number().optional(),
        creditorId:
          addCpoPaymentDetails && billingType?.includes('SEPA')
            ? yup.string().required(t('OpidReq'))
            : yup.string(),
        cpoIban:
          addCpoPaymentDetails && billingType?.includes('BANK_TRANSFER')
            ? yup
                .string()
                .required(t('ibanReq'))
                .matches(validators.IBAN, t('ibanValidation'))
            : yup.string(),
        cpoBankName:
          addCpoPaymentDetails && billingType?.includes('BANK_TRANSFER')
            ? yup.string().required(t('opBankReq'))
            : yup.string(),
        cpoBic:
          addCpoPaymentDetails && billingType?.includes('BANK_TRANSFER')
            ? yup
                .string()
                .required(t('bicOpreq'))
                .matches(validators.BIC, t('bicValidation'))
            : yup.string(),
        billingTemplateHeaderOne:
          addCpoPaymentDetails && totalHeader > 0
            ? yup.string().required(t('h1req'))
            : yup.string().optional(),
        billingTemplateHeaderTwo:
          addCpoPaymentDetails && totalHeader > 1
            ? yup.string().required(t('h2req'))
            : yup.string().optional(),
        billingTemplateHeaderThree:
          addCpoPaymentDetails && totalHeader > 2
            ? yup.string().required(t('h3req'))
            : yup.string().optional(),
        billingTemplateHeaderFour:
          addCpoPaymentDetails && totalHeader > 3
            ? yup.string().required(t('h4req'))
            : yup.string().optional(),
        billingTemplateFooterOne: yup.string(),
        billingTemplateFooterTwo: yup.string(),
        billingTemplateFooterThree: yup.string(),
        billingTemplateFooterFour: yup.string(),
        isChargingHistoryIncluded: yup.boolean(),
      }),
    [totalHeader, billingType, addCpoPaymentDetails, paymentBank, paymentSepa]
  );

  useEffect(() => {
    if (
      (billingType == null || billingType == '') &&
      addCpoPaymentDetails == true
    ) {
      if (submit > 0) {
        setShowBillingTypeReq(true);
      }
    } else {
      setShowBillingTypeReq(false);
      if (submit > 0) document.getElementById('subErstellung').click();
      setSubmit(0);
    }
  }, [submit]);

  const formik = useFormik({
    initialValues: {
      contactPersonName: '',
      contactPersonPosition: '',
      contactPersonPhone: '',
      contactPersonEmail: '',
      moneyCollectionGapInDays: '',
      creditorId: '',
      cpoIban: '',
      cpoBankName: '',
      cpoBic: '',
      billingTemplateHeaderOne: '',
      billingTemplateHeaderTwo: '',
      billingTemplateHeaderThree: '',
      billingTemplateHeaderFour: '',
      billingTemplateFooterOne: '',
      billingTemplateFooterTwo: '',
      billingTemplateFooterThree: '',
      billingTemplateFooterFour: '',
      isChargingHistoryIncluded: true,
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setIsSubmitting(true);
      const payload = {
        contactPersonDetails: {
          contactPersonName: values.contactPersonName,
          contactPersonPosition: values.contactPersonPosition,
          contactPersonPhone: values.contactPersonPhone,
          contactPersonEmail: values.contactPersonEmail,
        },
      };

      if (addCpoPaymentDetails) {
        payload['isChargingHistoryIncluded'] = values.isChargingHistoryIncluded;
        payload['billingType'] = billingType;

        payload['cpoPaymentDetails'] = {
          cpoPaymentPreference: billingType,
          cpoIban: values.cpoIban,
          cpoBankName: values.cpoBankName,
          cpoBic: values.cpoBic,
          creditorId: values.creditorId,
          moneyCollectionGapInDays: values.moneyCollectionGapInDays,
        };

        payload['billingTemplateHeader'] = {
          lineOne: values.billingTemplateHeaderOne,
          lineTwo: values.billingTemplateHeaderTwo,
          lineThree: values.billingTemplateHeaderThree,
          lineFour: values.billingTemplateHeaderFour,
        };

        payload['billingTemplateFooter'] = {
          lineOne: values.billingTemplateFooterOne,
          lineTwo: values.billingTemplateFooterTwo,
          lineThree: values.billingTemplateFooterThree,
          lineFour: values.billingTemplateFooterFour,
        };
      }
      setIsSubmitting(false);
      setPayload(payload);
    },
  });

  useEffect(() => {
    if (addCpoPaymentDetails === false) {
      setCanNext(setFinalValidationForNoBillingInfo());
    } else
      setCanNext(
        formik.isValid && formik.touched && setFinalValidationForBillingInfo()
      );
  }, [formik, billingType, paymentBank, paymentSepa, addCpoPaymentDetails]);

  const setFinalValidationForNoBillingInfo = () => {
    let error = false;
    if (
      formik.values.billingInfo &&
      formik.values.contactPersonEmail &&
      formik.values.contactPersonName &&
      formik.values.contactPersonPhone &&
      formik.values.contactPersonPosition
    ) {
      error = true;
    }
    return error;
  };
  const setFinalValidationForBillingInfo = () => {
    let error = false;
    if (paymentBank == true && paymentSepa == true) {
      if (
        formik.values.creditorId &&
        formik.values.cpoIban &&
        formik.values.cpoBankName &&
        formik.values.cpoBic
      ) {
        error = true;
      } else {
        error = false;
      }
    } else if (paymentBank == false && paymentSepa == false) {
      error = false;
    } else if (paymentBank == true) {
      if (
        formik.values.cpoIban &&
        formik.values.cpoBankName &&
        formik.values.cpoBic
      ) {
        error = true;
      } else {
        error = false;
      }
    } else if (paymentSepa == true) {
      if (formik.values.creditorId) {
        error = true;
      } else {
        error = false;
      }
    } else {
      error = true;
    }
    return error;
  };
  const setFinalBillingType = (paymentSepaInput, paymentBankInput) => {
    if (paymentBankInput == true && paymentSepaInput == true) {
      setBillingType('SEPA, BANK_TRANSFER');
    } else if (paymentBankInput == false && paymentSepaInput == false) {
      setBillingType(null);
    } else if (paymentBankInput == true && paymentSepaInput == false) {
      setBillingType('BANK_TRANSFER');
    } else {
      setBillingType('SEPA');
    }
  };

  const handleSepachange = (event) => {
    setPaymentSepa(event.target.checked);
    setFinalBillingType(event.target.checked, paymentBank);
  };
  const handleBankchange = (event) => {
    setPaymentBank(event.target.checked);
    setFinalBillingType(paymentSepa, event.target.checked);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Typography
            fontWeight={700}
            variant="subtitle1"
            id="autorisierung-uber-label"
          >
            {t('cpInfo')}
          </Typography>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                margin="dense"
                label="Name"
                variant="outlined"
                required={true}
                fullWidth
                name={'contactPersonName'}
                value={formik.values.contactPersonName}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonName &&
                  Boolean(formik.errors.contactPersonName)
                }
                helperText={
                  formik.touched.contactPersonName &&
                  formik.errors.contactPersonName
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="contactPersonPosition"
                label={t('job')}
                variant="outlined"
                required
                fullWidth
                name={'contactPersonPosition'}
                value={formik.values.contactPersonPosition}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonPosition &&
                  Boolean(formik.errors.contactPersonPosition)
                }
                helperText={
                  formik.touched.contactPersonPosition &&
                  formik.errors.contactPersonPosition
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="contactPersonPhone"
                label={t('PhoneNum')}
                variant="outlined"
                fullWidth
                required
                my={2}
                name={'contactPersonPhone'}
                value={formik.values.contactPersonPhone}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonPhone &&
                  Boolean(formik.errors.contactPersonPhone)
                }
                helperText={
                  formik.touched.contactPersonPhone &&
                  formik.errors.contactPersonPhone
                }
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                margin="dense"
                id="contactPersonEmail"
                label="E-Mail"
                variant="outlined"
                required
                fullWidth
                my={2}
                name={'contactPersonEmail'}
                value={formik.values.contactPersonEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.contactPersonEmail &&
                  Boolean(formik.errors.contactPersonEmail)
                }
                helperText={
                  formik.touched.contactPersonEmail &&
                  formik.errors.contactPersonEmail
                }
              />
            </Grid>
          </Grid>

          <br></br>
          <Typography
            fontWeight={700}
            variant="subtitle1"
            id="autorisierung-uber-label"
          >
            <div>{t('titleBilling')}</div>
          </Typography>
          <Field
            margin="none"
            sx={{ width: '100%' }}
            component={RadioGroup}
            aria-labelledby="billing-info-label"
            name="billingInfo"
            value={addCpoPaymentDetails ? 'billingInfo' : 'noBillingInfo'}
            onBlur={formik.handleChange}
            onChange={(e) => {
              setAddCpoPaymentDetails(e.target.value === 'billingInfo');
            }}
          >
            <FormControlLabel
              value={'billingInfo'}
              sx={{ width: 'fit-content' }}
              control={<Radio disabled={isSubmitting} />}
              label={t('titleOp1')}
              disabled={isSubmitting}
            />
            <FormControlLabel
              value={'noBillingInfo'}
              sx={{ width: 'fit-content' }}
              control={<Radio disabled={isSubmitting} />}
              label={t('titleOp2')}
              disabled={isSubmitting}
            />
          </Field>
          {addCpoPaymentDetails && (
            <>
              <br></br>
              <Typography
                fontWeight={700}
                variant="subtitle1"
                id="autorisierung-uber-label"
              >
                {billingType?.includes('BANK_TRANSFER') ? (
                  <div>{t('infoInvoice')}</div>
                ) : (
                  <div>{t('infoInvoice')}</div>
                  // <div>{t('InfoInv')}</div>
                )}
              </Typography>
              <Grid container pacing={6}>
                <Grid item md={9}>
                  <TextField
                    margin="dense"
                    id="billingTemplateHeaderOne"
                    label={t('Hdr1')}
                    variant="outlined"
                    required={
                      addCpoPaymentDetails && totalHeader > 0 ? true : false
                    }
                    fullWidth
                    name={'billingTemplateHeaderOne'}
                    my={2}
                    value={formik.values.billingTemplateHeaderOne}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.billingTemplateHeaderOne &&
                      Boolean(formik.errors.billingTemplateHeaderOne)
                    }
                    helperText={
                      formik.touched.billingTemplateHeaderOne &&
                      formik.errors.billingTemplateHeaderOne
                    }
                  />
                </Grid>
                {totalHeader === 1 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalHeader(2);
                      }}
                    >
                      <AddIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader > 1 ? (
                  <Grid item md={9}>
                    <TextField
                      margin="dense"
                      id="billingTemplateHeaderTwo"
                      label={t('Hdr2')}
                      variant="outlined"
                      required={
                        addCpoPaymentDetails && totalHeader > 1 ? true : false
                      }
                      fullWidth
                      name={'billingTemplateHeaderTwo'}
                      my={2}
                      value={formik.values.billingTemplateHeaderTwo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billingTemplateHeaderTwo &&
                        Boolean(formik.errors.billingTemplateHeaderTwo)
                      }
                      helperText={
                        formik.touched.billingTemplateHeaderTwo &&
                        formik.errors.billingTemplateHeaderTwo
                      }
                    />
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader === 2 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalHeader(1);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader === 2 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalHeader(3);
                      }}
                    >
                      <AddIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader > 2 ? (
                  <Grid item md={9}>
                    <TextField
                      margin="dense"
                      id="billingTemplateHeaderThree"
                      label={t('Hdr3')}
                      variant="outlined"
                      required={totalHeader > 2 ? true : false}
                      fullWidth
                      name={'billingTemplateHeaderThree'}
                      my={2}
                      value={formik.values.billingTemplateHeaderThree}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billingTemplateHeaderThree &&
                        Boolean(formik.errors.billingTemplateHeaderThree)
                      }
                      helperText={
                        formik.touched.billingTemplateHeaderThree &&
                        formik.errors.billingTemplateHeaderThree
                      }
                    />
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader === 3 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalHeader(2);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader === 3 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalHeader(4);
                      }}
                    >
                      <AddIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader > 3 ? (
                  <Grid item md={9}>
                    <TextField
                      margin="dense"
                      id="billingTemplateHeaderFour"
                      label={t('Hdr4')}
                      variant="outlined"
                      required={totalHeader > 3 ? true : false}
                      fullWidth
                      name={'billingTemplateHeaderFour'}
                      my={2}
                      value={formik.values.billingTemplateHeaderFour}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billingTemplateHeaderFour &&
                        Boolean(formik.errors.billingTemplateHeaderFour)
                      }
                      helperText={
                        formik.touched.billingTemplateHeaderFour &&
                        formik.errors.billingTemplateHeaderFour
                      }
                    />
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalHeader > 3 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalHeader(3);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
              </Grid>
              {/* footer starts here  */}
              <br></br>
              <Grid container pacing={6}>
                <Grid item md={9}>
                  <TextField
                    margin="dense"
                    id="billingTemplateFooterOne"
                    label={t('ftr1')}
                    variant="outlined"
                    fullWidth
                    name={'billingTemplateFooterOne'}
                    my={2}
                    value={formik.values.billingTemplateFooterOne}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.billingTemplateFooterOne &&
                      Boolean(formik.errors.billingTemplateFooterOne)
                    }
                    helperText={
                      formik.touched.billingTemplateFooterOne &&
                      formik.errors.billingTemplateFooterOne
                    }
                  />
                </Grid>
                {totalFooter === 1 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalFooter(2);
                      }}
                    >
                      <AddIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter > 1 ? (
                  <Grid item md={9}>
                    <TextField
                      margin="dense"
                      id="billingTemplateFooterTwo"
                      label={t('ftr2')}
                      variant="outlined"
                      fullWidth
                      name={'billingTemplateFooterTwo'}
                      my={2}
                      value={formik.values.billingTemplateFooterTwo}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billingTemplateFooterTwo &&
                        Boolean(formik.errors.billingTemplateFooterTwo)
                      }
                      helperText={
                        formik.touched.billingTemplateFooterTwo &&
                        formik.errors.billingTemplateFooterTwo
                      }
                    />
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter === 2 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalFooter(1);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter === 2 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalFooter(3);
                      }}
                    >
                      <AddIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter > 2 ? (
                  <Grid item md={9}>
                    <TextField
                      margin="dense"
                      id="billingTemplateFooterThree"
                      label={t('ftr3')}
                      variant="outlined"
                      fullWidth
                      name={'billingTemplateFooterThree'}
                      my={2}
                      value={formik.values.billingTemplateFooterThree}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billingTemplateFooterThree &&
                        Boolean(formik.errors.billingTemplateFooterThree)
                      }
                      helperText={
                        formik.touched.billingTemplateFooterThree &&
                        formik.errors.billingTemplateFooterThree
                      }
                    />
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter === 3 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalFooter(2);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter === 3 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalFooter(4);
                      }}
                    >
                      <AddIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter > 3 ? (
                  <Grid item md={9}>
                    <TextField
                      margin="dense"
                      id="billingTemplateFooterFour"
                      label={t('ftr4')}
                      variant="outlined"
                      fullWidth
                      name={'billingTemplateFooterFour'}
                      my={2}
                      value={formik.values.billingTemplateFooterFour}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.billingTemplateFooterFour &&
                        Boolean(formik.errors.billingTemplateFooterFour)
                      }
                      helperText={
                        formik.touched.billingTemplateFooterFour &&
                        formik.errors.billingTemplateFooterFour
                      }
                    />
                  </Grid>
                ) : (
                  <div></div>
                )}
                {totalFooter > 3 ? (
                  <Grid item marginLeft={2} md={1}>
                    <SmallButton
                      size="medium"
                      mr={2}
                      onClick={() => {
                        setTotalFooter(3);
                      }}
                    >
                      <DeleteOutlineIcon />
                    </SmallButton>
                  </Grid>
                ) : (
                  <div></div>
                )}
              </Grid>
              <br></br>

              {/* heading and tage starts */}
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <Typography
                    fontWeight={700}
                    variant="subtitle1"
                    id="autorisierung-uber-label"
                  >
                    {t('paymenthow')}
                  </Typography>
                  {(billingType == null || billingType == '') &&
                  showBillingTypeReq == true ? (
                    <Typography align="center" color={'red'}>
                      {t('BillingReq')}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item md={6}>
                  <Grid item md={12}>
                    <Field
                      required
                      fullWidth
                      component={TextField}
                      id="moneyCollectionGapInDays"
                      label={t('noOfDaysUntillPayment')}
                      name="moneyCollectionGapInDays"
                      margin="none"
                      type="number"
                      value={formik.values.moneyCollectionGapInDays}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.moneyCollectionGapInDays &&
                        Boolean(formik.errors.moneyCollectionGapInDays)
                      }
                      helperText={
                        formik.touched.moneyCollectionGapInDays &&
                        formik.errors.moneyCollectionGapInDays
                      }
                      inputProps={{ min: 0, step: 1 }}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {t('tages')}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* heading and tage ends */}

              {/* sepa mode starts here  */}
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <MenuItem>
                    <Checkbox
                      size="small"
                      onBlur={formik.handleChange}
                      onChange={handleSepachange}
                      checked={paymentSepa}
                    />
                    <ListItemText>{t('sepamode')}</ListItemText>
                  </MenuItem>
                </Grid>
                <Grid item md={6}>
                  {paymentSepa == true ? (
                    <Grid item md={12}>
                      <TextField
                        margin="dense"
                        id="creditorId"
                        label={t('opidlab')}
                        variant="outlined"
                        required={true}
                        fullWidth
                        name={'creditorId'}
                        my={2}
                        value={formik.values.creditorId}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.creditorId &&
                          Boolean(formik.errors.creditorId)
                        }
                        helperText={
                          formik.touched.creditorId && formik.errors.creditorId
                        }
                      />
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>
              {/* sepa mode ends here */}

              {/* bank mode starts here  */}
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <MenuItem>
                    <Checkbox
                      size="small"
                      onChange={handleBankchange}
                      checked={paymentBank}
                    />
                    <ListItemText>{t('rechnung')}</ListItemText>
                  </MenuItem>
                </Grid>
                <Grid item md={6}>
                  {paymentBank == true ? (
                    <Grid item md={12}>
                      <TextField
                        margin="dense"
                        id="cpoBankName"
                        label={t('bankMode')}
                        variant="outlined"
                        required
                        fullWidth
                        name={'cpoBankName'}
                        my={2}
                        value={formik.values.cpoBankName}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.cpoBankName &&
                          Boolean(formik.errors.cpoBankName)
                        }
                        helperText={
                          formik.touched.cpoBankName &&
                          formik.errors.cpoBankName
                        }
                      />
                    </Grid>
                  ) : (
                    <div></div>
                  )}
                </Grid>
              </Grid>

              {paymentBank == true ? (
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <TextField
                      margin="dense"
                      id="cpoIban"
                      label={t('iban')}
                      variant="outlined"
                      required
                      fullWidth
                      name={'cpoIban'}
                      my={2}
                      value={formik.values.cpoIban}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.cpoIban && Boolean(formik.errors.cpoIban)
                      }
                      helperText={
                        formik.touched.cpoIban && formik.errors.cpoIban
                      }
                    />
                  </Grid>
                  <Grid item md={6}>
                    <TextField
                      margin="dense"
                      id="cpoBic"
                      label={t('bic')}
                      variant="outlined"
                      required
                      fullWidth
                      name={'cpoBic'}
                      my={2}
                      value={formik.values.cpoBic}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.cpoBic && Boolean(formik.errors.cpoBic)
                      }
                      helperText={formik.touched.cpoBic && formik.errors.cpoBic}
                    />
                  </Grid>
                </Grid>
              ) : (
                <div></div>
              )}

              {/* bank mode ends here */}
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <Typography
                    fontWeight={700}
                    variant="subtitle1"
                    id="autorisierung-uber-label"
                  >
                    {t('ProofLabel')}
                  </Typography>
                  <Field
                    margin="none"
                    sx={{ width: '100%' }}
                    component={RadioGroup}
                    aria-labelledby="abrechnungsart-label"
                    name="isChargingHistoryIncluded"
                    value={formik.values.isChargingHistoryIncluded}
                    onChange={formik.handleChange}
                  >
                    <Grid item md={6}>
                      <FormControlLabel
                        value={false}
                        sx={{ width: 'auto' }}
                        control={<Radio disabled={isSubmitting} />}
                        label={t('no')}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel
                        value={true}
                        sx={{ width: 'auto' }}
                        control={<Radio disabled={isSubmitting} />}
                        label={t('yes')}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  </Field>
                </Grid>
              </Grid>
              {/**
               * this button is hidden and used to trigger submit from outside of the component
               */}
            </>
          )}

          <Button
            sx={{ display: 'none' }}
            id="subErstellung"
            type="submit"
          ></Button>
        </form>
      </FormikProvider>
    </>
  );
}

export default OperatorForm;
