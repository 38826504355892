import 'dayjs/locale/de';
import React from 'react';
/**
 * @param {PropTypes.InferProps<UserCreationFormERPInitial.propTypes>} props
 */
// eslint-disable-next-line no-empty-pattern
const UserCreationFormERPInitial = ({}) => {
  // created dummy component to make state changes in  UserCreationFormERP after getting billing type at step 3
  // eslint-disable-next-line react/react-in-jsx-scope
  return <div></div>;
};

UserCreationFormERPInitial.propTypes = {};

export default UserCreationFormERPInitial;
