/* eslint-disable no-unused-vars */
import {
  List as MuiList,
  ListItem as MuiListItem,
  Paper,
  Button,
  Skeleton,
  Stack,
  styled,
  Menu,
  MenuItem,
  useTheme,
  ThemeProvider,
  Typography,
  TableCell,
  Typography as MuiTypography,
} from '@mui/material';
import EvStationIcon from '@mui/icons-material/EvStation';
import MultipleBillingStatusEdit from '../../components/multipleEditDialog/MultipleBillingStatusEdit';
import DataTable from '../../components/DataTable';
import StepperPopup from '../../components/StepperPopup';
import { createTheme } from '@mui/material/styles';
import { amber, blue, red } from '@mui/material/colors';
import { Box } from '@mui/system';
import React, { useMemo, useEffect, useState, Suspense } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import useSWR from 'swr';
import { ArrowForward } from '@mui/icons-material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Charginstations from '../../components/ChargingStation';
import { useAuth } from '../../services/contexts/AuthContext';
import Feedback from '../../components/DataTable/Feedback';
import DetailsWrapper from '../../layouts/DetailsWrapper';
import {
  formatCurrency,
  formatDecimal,
} from '../../services/utils/format-numbers';
import EditLocationInformation from './EditLocationInformation';
import { useTranslation } from 'react-i18next';
import api from '../../Middleware/api/api';
import { PAGE_SIZE, MIN_PAGE_SIZE } from '../../services/utils/units-constants';
import jwtDecode from 'jwt-decode';
var rows = [];
// eslint-disable-next-line no-unused-vars
const List = styled(MuiList)(({ theme }) => ({
  paddingLeft: 0,
}));

// eslint-disable-next-line no-unused-vars
const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            paddingLeft: 8,
            '&[data-colindex="4"]': {
              '& > *': {
                justifyContent: 'center',
                textAlign: 'center',
              },
              '& .MuiButton-root': {
                margin: '0px',
              },
            },
            '&[data-colindex="3"], &[data-colindex="1"], &[data-colindex="2"]':
              {
                '& > *': {
                  justifyContent: 'left',
                },
                '& .MuiButton-root': {
                  marginLeft: '0',
                },
              },
            '&[data-colindex="6"], &[data-colindex="5"]': {
              '& > *': {
                justifyContent: 'right',
              },
            },
          },
        },
      },
    },
  });

// eslint-disable-next-line no-unused-vars
const ListItem = styled(MuiListItem)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  paddingLeft: 0,
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const LocationDetails = () => {
  const { t, i18n } = useTranslation();
  const [isPreview, setIsPreview] = useState(true);
  const { token } = useAuth();
  const { details } = useParams();
  const renderDataSummaryChargingInfrastructure = {
    '': '',
  };
  const renderDataSummaryContractStatuses = {
    '': '',
  };
  const decodedToken = token ? jwtDecode(token) : null;
  const [isAdminRole, setIsAdminRole] = useState(
    decodedToken?.resource_access?.admin_oli_move?.roles.some(
      (role) => role === 'oli_move_admin'
    )
  );
  const [locationInfo, setLocationInfo] = useState(null);
  const [operatorInfo, setOperatorInfo] = useState(null);
  const [successfullPrakingLotUpdated, setSuccessfullPrakingLotUpdated] =
    useState(0);
  const [showError, setShowError] = useState(false);
  const [refreshTheData, setRefreshTheData] = useState(0);

  const getLocations = async () => {
    api
      .fetchLocationById(token, details)
      .then((response) => {
        if (response.data.data == null) {
          let rows = [];
          setLocationInfo(rows);
        } else {
          setLocationInfo(response.data.data);
        }
      })
      .catch((error) => {
        setShowError(true);
      });
  };

  useEffect(() => {
    if (refreshTheData >= 0) {
      getLocations();
    }
    setRefreshTheData(0);
  }, [successfullPrakingLotUpdated, refreshTheData]);

  const loadingLocation = !locationInfo;
  console.log(loadingLocation);
  useEffect(() => {
    if (locationInfo) {
      setOperatorInfo(locationInfo?.operatorDetail);
    }
  }, [locationInfo]);
  function closeErrorFeedback() {
    setShowError(false);
  }
  const enablePreveiw = () => {
    setIsPreview(false);
  };
  const disablePreveiw = () => {
    setIsPreview(true);
  };

  function UsersTable() {
    const { t } = useTranslation();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selected, setSelected] = useState([]);

    const [fetchingData, setFetchingData] = React.useState(true);
    const [operatorContract, setOperatorContract] = React.useState([]);
    const { token } = useAuth();

    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
    const [totalRecords, setTotalRecords] = useState(0);

    const fetchUsersbyLocation = async () => {
      try {
        api
          .fetchUsersByLocationId(
            token,
            locationInfo?.locationId,
            currentPage,
            pageSize
          )
          .then(
            (response) => {
              if (response.data.data == null) {
                rows = [];
                setLocationData([]);
              } else {
                setTotalRecords(response?.data?.data?.totalNumberOfRecords);
                rows = response?.data?.data?.response;
                const rowsWithDetail = rows.map((row) => ({
                  ...row,
                  details: row,
                  contractStatus: row.contractStatus,
                  billingStatus:
                    row.billingStatus === 'PAID'
                      ? t('LBLBillPaid')
                      : row.billingStatus === 'UNPAID'
                        ? t('LBLBillUnpaid')
                        : null,
                }));

                setOperatorContract(rowsWithDetail.reverse());
                setFetchingData(false);
              }
            },
            () => {
              setOperatorContract([]);
            }
          );
      } catch (exception) {
        rows = [];
        setOperatorContract([]);
      }
    };

    useEffect(() => {
      fetchUsersbyLocation();
    }, [currentPage, pageSize]);

    useEffect(() => {
      setSelected(selectedRows.map((index) => operatorContract[index]));
    }, [selectedRows]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [payload, setPayload] = useState([]);
    const [submit, setSubmit] = useState(0);
    const [showFeedback, setShowFeedback] = useState(false);
    const [showErrorFeedback, setShowErrorFeedback] = useState(false);
    const [showNoEntriesFeedback, setShowNoEntriesFeedback] = useState(false);
    const [showNullBillingStatusFeedback, setShowNullBillingStatusFeedback] =
      useState(false);
    const [
      showMultiNullBillingStatusFeedback,
      setShowMultiNullBillingStatusFeedback,
    ] = useState(false);
    const [checkStatusChange, setCheckStatusChange] = useState(false);

    const [openElem, setOpenElem] = useState(null);
    const [anchorIconButton, setAnchorIconButton] = useState(null);
    const handleCloseIconMenu = () => {
      setOpenElem(null);
      setAnchorIconButton(null);
    };

    const columns = useMemo(() => [
      {
        name: 'endCustomerId',
        label: t('LBLCustomerNum'),
        options: {
          filter: false,
          customBodyRender: (value) => {
            return <Typography align="left">{value}</Typography>;
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLCustomerNum')}
            </TableCell>
          ),
        },
      },
      {
        name: 'name',
        label: 'Name',
        options: {
          filter: false,
          customBodyRender: (value) => {
            return <Typography align="left">{value}</Typography>;
          },
          setCellHeaderProps: () => {
            return { align: 'start' };
          },
          setCellProps: () => {
            return { align: 'left' };
          },
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {'Name'}
          </TableCell>
        ),
      },
      {
        name: 'parkingLot',
        label: t('LBLParkingLotNum'),
        options: {
          filter: false,
          customBodyRender: (value) => {
            return <Typography align="left">{value.join(', ')}</Typography>;
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              sx={{
                textAlign: 'left',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLParkingLotNum')}
            </TableCell>
          ),
        },
      },

      {
        name: 'contractStatusMapping',
        label: t('LBLUserStatus'),
        options: {
          filter: false,
          customBodyRender: (value) => {
            if (!value) return null;
            return (
              <Typography align="center" color={value.colourCode}>
                {t(value.enumName)}
              </Typography>
            );
          },
          customHeadRender: () => (
            <TableCell
              align="center"
              variant="head"
              sx={{
                textAlign: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
              }}
            >
              {t('LBLUserStatus')}
            </TableCell>
          ),
        },
      },
      {
        name: 'details',
        label: ' ',
        options: {
          sort: false,
          filter: false,
          customBodyRender(value) {
            return (
              <>
                {/* comented out this edit pencil icon as it is not need for now on dashboard, 
                    AND all the related workflows will not work but the actual code is here for future use may be. */}
                {/* <IconButton 
                        onClick={handleClickIconMenu(value)}
                      >
                        <EditIcon />
                      </IconButton> */}
                <Menu
                  anchorEl={anchorIconButton}
                  open={openElem === value}
                  key={value}
                  onClose={handleCloseIconMenu}
                  MenuListProps={{
                    'aria-labelledby': 'edit-selected',
                  }}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <MenuItem
                    key={value}
                    onClick={() => {
                      if (value.billingStatus) {
                        setSelected([value]);
                        setIsOpen(true);
                        handleCloseIconMenu();
                      } else {
                        setShowNullBillingStatusFeedback(true);
                        handleCloseIconMenu();
                      }
                    }}
                  >
                    <Typography align="center" sx={{ width: '100%' }}>
                      Zahlungsstatus ändern
                    </Typography>
                  </MenuItem>
                </Menu>
                <Button
                  component={NavLink}
                  to={`/nutzer/${value.endCustomerId}/`}
                  variant="text"
                >
                  Details
                </Button>
              </>
            );
          },
        },
      },
    ]);

    return (
      <>
        <ThemeProvider
          theme={(appTheme) => ({
            ...appTheme,
            components: {
              ...appTheme.components,
              ...dataTableTheme(appTheme).components,
            },
          })}
        >
          <DataTable
            data={operatorContract}
            columns={columns}
            options={{
              textLabels: {
                body: {
                  noMatch: fetchingData ? 'Nutzer holen...' : t('LBLnoUsers'),
                },
              },
              pagination: totalRecords > MIN_PAGE_SIZE ? true : false,

              search: true,
              serverSide: true,
              count: totalRecords,
              rowsPerPage: pageSize,
              rowsPerPageOptions: PAGE_SIZE,
              onTableChange: (action, tableState) => {
                if (action === 'changePage') {
                  setCurrentPage(tableState.page);
                } else if (action === 'changeRowsPerPage') {
                  setPageSize(tableState.rowsPerPage);
                } else {
                  // console.log('action not handled.');
                }
              },
              totalRecords: totalRecords,
              currentPage: currentPage,
            }}
            getData={(seletedPageSize, currentPageVal) => {
              setCurrentPage(currentPageVal);
              setPageSize(seletedPageSize);
            }}
            title={t('LBLUSR')}
          ></DataTable>
        </ThemeProvider>
        <StepperPopup
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title={t('LBLChangePayment')}
          steps={[
            {
              label: t('LBLChangeEnter'),
              component: (
                <MultipleBillingStatusEdit
                  data={selected}
                  payload={payload}
                  setPayload={setPayload}
                  submit={submit}
                  setShowFeedback={setShowFeedback}
                  setShowErrorFeedback={setShowErrorFeedback}
                  checkStatusChange={checkStatusChange}
                />
              ),
              nextAction: {
                label: t('LBLFurther'),
                icon: <ArrowForward />,
                action: () => {
                  setCheckStatusChange(true);
                },
                canNext:
                  !payload.filter((value) => value?.changed === false).length >
                  0,
              },
            },
            {
              label: t('LBLConfirmChanges'),
              component: (
                <MultipleBillingStatusEdit
                  data={selected}
                  payload={payload}
                  submit={submit}
                  setPayload={setPayload}
                  setShowFeedback={setShowFeedback}
                  setShowErrorFeedback={setShowErrorFeedback}
                  isReadOnly
                />
              ),
              nextAction: {
                label: t('LBLSave'),
                icon: <></>,
                action: () => {
                  setSubmit((prevState) => prevState + 1);
                  setIsOpen(false);
                },
                canNext: false,
              },
            },
          ]}
        />
        <Feedback
          open={showFeedback}
          title={t('LBLPStatusChanged')}
          message={
            <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
              {t('LBLParaPayment')}
            </Typography>
          }
          severity="warning"
          handleClose={() => {
            setPayload([]);
            setSelected([]);
            setShowFeedback(false);
          }}
        />
        <Feedback
          open={showErrorFeedback}
          message={
            <Typography
              variant="subtitle1"
              fontWeight={600}
              sx={{ width: 400, mx: 10 }}
            >
              {t('LBLServerAgain')}
            </Typography>
          }
          severity="warning"
          handleClose={() => {
            setPayload([]);
            setSelected([]);
            setShowErrorFeedback(false);
          }}
        />
        <Feedback
          open={showNoEntriesFeedback}
          message={
            <Typography
              variant="subtitle1"
              fontWeight={400}
              sx={{ width: 400, mx: 10 }}
            >
              {t('LBLSelectOneUser')}
            </Typography>
          }
          severity="warning"
          handleClose={() => {
            setPayload([]);
            setSelected([]);
            setShowNoEntriesFeedback(false);
          }}
        />
        <Feedback
          open={showNullBillingStatusFeedback}
          title={t('LBLWrongSelection')}
          message={
            <Typography
              variant="subtitle1"
              fontWeight={400}
              sx={{ width: 400, mx: 10 }}
            >
              {t('LBLNoInvoice')}
            </Typography>
          }
          severity="warning"
          handleClose={() => {
            setPayload([]);
            setSelected([]);
            setShowNullBillingStatusFeedback(false);
          }}
        />
        <Feedback
          open={showMultiNullBillingStatusFeedback}
          title={t('LBLWrongSelection')}
          message={
            <Typography
              variant="subtitle1"
              fontWeight={400}
              sx={{ width: 400, mx: 10 }}
            >
              {t('LBLNoInvoiceUser')}
            </Typography>
          }
          severity="warning"
          handleClose={() => {
            setPayload([]);
            setSelected([]);
            setShowMultiNullBillingStatusFeedback(false);
          }}
        />
      </>
    );
  }

  return (
    <>
      <DetailsWrapper
        loading={loadingLocation}
        backButtonConfig={{
          label: t('LBLBackToLocation'),
          url: '/standorte',
        }}
        title={t('LBLLocationMgmt')}
        helmet="Standortverwaltung"
        contract={
          <EditLocationInformation
            locationInfo={locationInfo}
            locationId={locationInfo?.locationId}
            setRefreshTheData={setRefreshTheData}
          />
        }
      >
        <Stack direction="row">
          <Paper sx={{ px: 2, py: 4, mb: 12, width: '100%' }}>
            <Stack direction="row" sx={{ width: '100%' }}>
              <Box flexGrow={1}>
                <MuiTypography variant="h3" component="h2" gutterBottom>
                  {t('LBLLocationInfo')}
                </MuiTypography>
                <List>
                  <ListItem>
                    <Typography>{t('LBLLocationId')} </Typography>
                    <Typography>
                      {loadingLocation ? (
                        <Skeleton width={35} />
                      ) : (
                        locationInfo?.locationId
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>{t('LBLLocationName')}</Typography>
                    <Typography>
                      {loadingLocation ? (
                        <Skeleton width={150} />
                      ) : (
                        locationInfo?.locationName
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>{t('LBLAddress')}</Typography>
                    <Typography align="right">
                      {loadingLocation ? (
                        <>
                          <Skeleton width={130} />
                          <Skeleton width={130} />
                        </>
                      ) : (
                        <>
                          <Typography>
                            {locationInfo?.locationAddress.careOf ? (
                              <>
                                c/o {locationInfo?.locationAddress.careOf}{' '}
                                <br />
                              </>
                            ) : (
                              <></>
                            )}
                            {locationInfo?.locationAddress.street} <br />
                            {locationInfo?.locationAddress.zipCode}{' '}
                            {locationInfo?.locationAddress.city}
                          </Typography>
                        </>
                      )}
                    </Typography>
                  </ListItem>
                </List>
                <MuiTypography variant="h3" component="h2" gutterBottom>
                  {t('LBLOpInfo')}
                </MuiTypography>
                <List>
                  <ListItem>
                    <Typography> {t('LBLOpId')}</Typography>
                    <Typography>
                      {loadingLocation ? null : operatorInfo?.cpoId}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>Name</Typography>
                    <Typography>
                      {loadingLocation ? null : operatorInfo?.cpoName}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>{t('LBLAddress')} </Typography>
                    <Typography align="right">
                      {operatorInfo?.cpoAddress.street} <br />
                      {operatorInfo?.cpoAddress.zipCode}{' '}
                      {operatorInfo?.cpoAddress.city}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
              <Box flexGrow={1}>
                <MuiTypography variant="h3" component="h2" gutterBottom>
                  {t('LBLCharginInfra')}
                </MuiTypography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={4}
                >
                  <Typography
                    variant="h5"
                    color="text.primary"
                    component="h3"
                    fontWeight={600}
                  >
                    {t('LBLTotalCHarginSt')}
                  </Typography>
                  <Typography>
                    {loadingLocation ? (
                      <Skeleton width={10} />
                    ) : !isAdminRole ? (
                      locationInfo?.chargingInfrastructureMetrics
                        ?.totalChargingStations -
                      locationInfo?.chargingInfrastructureMetrics
                        ?.statusesSummary?.connRemoved?.number
                    ) : (
                      locationInfo?.chargingInfrastructureMetrics
                        ?.totalChargingStations
                    )}
                  </Typography>
                </Stack>
                <>
                  {Object.entries(
                    locationInfo?.chargingInfrastructureMetrics &&
                      locationInfo?.chargingInfrastructureMetrics
                        ?.statusesSummary
                      ? locationInfo?.chargingInfrastructureMetrics
                          ?.statusesSummary
                      : renderDataSummaryChargingInfrastructure
                  )
                    .filter(([key, value]) => {
                      if (!isAdminRole && key === 'connRemoved') {
                        return false;
                      }
                      return true;
                    })
                    .map(([key, value]) => (
                      <Stack
                        key={key}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        pr={4}
                        my={4}
                      >
                        <Typography variant="body1" color={value.color}>
                          {t(key)}
                        </Typography>
                        <Typography variant="body1" align="right" id={key}>
                          {value.number}
                        </Typography>
                      </Stack>
                    ))}
                </>
                <Typography
                  variant="h5"
                  color="text.primary"
                  component="h3"
                  fontWeight={600}
                >
                  EMS
                </Typography>
                <List>
                  <ListItem>
                    <Typography>EMS Model</Typography>
                    <Typography>
                      {loadingLocation ? (
                        <Skeleton width={150} />
                      ) : (
                        locationInfo?.emsModel
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography>EMS-ID</Typography>
                    <Typography>
                      {loadingLocation ? (
                        <Skeleton width={150} />
                      ) : (
                        locationInfo?.emsId
                      )}
                    </Typography>
                  </ListItem>
                </List>
              </Box>

              <Box flexGrow={1}>
                <MuiTypography variant="h3" component="h2" gutterBottom>
                  {t('LBLUser')}
                </MuiTypography>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={4}
                >
                  <Typography
                    variant="h5"
                    color="text.primary"
                    component="h3"
                    fontWeight={600}
                  >
                    {t('LBLTotalUsers')}
                  </Typography>
                  <Typography>
                    {loadingLocation ? (
                      <Skeleton width={10} />
                    ) : (
                      locationInfo?.contractMetrics?.totalContractStatuses
                    )}
                  </Typography>
                </Stack>
                <>
                  {Object.entries(
                    locationInfo?.contractMetrics &&
                      locationInfo?.contractMetrics?.statusesSummary
                      ? locationInfo?.contractMetrics?.statusesSummary
                      : renderDataSummaryContractStatuses
                  ).map(([key, value]) => (
                    <Stack
                      key={key}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      pr={4}
                      my={4}
                    >
                      <Typography variant="body1" color={value.color}>
                        {t(key)}
                      </Typography>
                      <Typography variant="body1" align="right" id={key}>
                        {value.number}
                      </Typography>
                    </Stack>
                  ))}
                </>

                <MuiTypography variant="h3" component="h2" gutterBottom mt={4}>
                  KPI
                </MuiTypography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  pr={4}
                  pt={4}
                  pb={4}
                >
                  <Typography
                    variant="h5"
                    color="text.primary"
                    component="h3"
                    fontWeight={600}
                  >
                    {t('LBLLastMonth')}
                  </Typography>
                </Stack>
                <List>
                  <ListItem>
                    <Typography> {t('LBLAmountenergyCharged')} </Typography>
                    <Typography>
                      {loadingLocation ? (
                        <Skeleton width={10} />
                      ) : i18n.language === 'en' ? (
                        formatDecimal(
                          locationInfo?.locationMetrics?.totalEnergy || 0
                        ).replace(/,/g, '.') + ' kWh'
                      ) : (
                        formatDecimal(
                          locationInfo?.locationMetrics?.totalEnergy || 0
                        ) + ' kWh'
                      )}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography> {t('LBLTotalSales')} </Typography>
                    <Typography>
                      {loadingLocation ? (
                        <Skeleton width={10} />
                      ) : i18n.language === 'en' ? (
                        formatCurrency(
                          locationInfo?.locationMetrics?.totalRevenue || 0
                        ).replace(/,/g, '.')
                      ) : (
                        formatCurrency(
                          locationInfo?.locationMetrics?.totalRevenue || 0
                        )
                      )}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Stack>
          </Paper>
        </Stack>
        <div style={{ margin: '10px' }}>
          <Button
            style={{ width: '60px', height: '40px', margin: '10px' }}
            variant="contained"
            color={isPreview ? 'primary' : 'inherit'}
            onClick={disablePreveiw}
          >
            <EvStationIcon />
          </Button>
          <Button
            style={{ width: '60px', height: '40px' }}
            variant="contained"
            color={isPreview ? 'inherit' : 'primary'}
            onClick={enablePreveiw}
          >
            <PeopleOutlineIcon />
          </Button>
        </div>
        {locationInfo ? (
          <div>
            {isPreview == true ? (
              <Charginstations
                locationId={locationInfo?.locationId}
                successUpdate={setSuccessfullPrakingLotUpdated}
              />
            ) : (
              // users
              <UsersTable />
            )}
          </div>
        ) : (
          <div> Loading</div>
        )}

        <Suspense fallback={<div />}>
          <Feedback
            open={showError}
            message={
              <Typography
                variant="subtitle1"
                fontWeight={600}
                sx={{ width: 400, mx: 10 }}
              >
                {t('LBLServerAgain')}
              </Typography>
            }
            severity="warning"
            handleClose={closeErrorFeedback}
          />
        </Suspense>
      </DetailsWrapper>
    </>
  );
};

export default LocationDetails;
