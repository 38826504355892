import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, TextField, Button, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import shadows from '@mui/material/styles/shadows';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ChargingStationComponent from './ChargingStationComponent';
import CurrentStatusComponent from './CurrentStatusComponent';
import EditChargingStationInformation from './EditChargingStationInformation';
import DetailsWrapper from '../../layouts/DetailsWrapper';
import FailFeedback from '../../components/DataTable/Feedback';
import Table from './TableCharging';
import { useAuth } from '../../services/contexts/AuthContext';
import api from '../../Middleware/api/api';

const StationDetails = () => {
  const [loading, setLoading] = useState(false);
  const [chargingStation, setChargingStation] = useState({});
  const [startDate, setStartDate] = useState(calculatedates(7));
  const [endDate, setEndDate] = useState(calculatedates(0));
  const [error204, setError204] = useState(false);
  const [error, setError] = useState(false);
  const [refreshTheData, setRefreshTheData] = useState(0);
  const { t, i18n } = useTranslation();
  const { token } = useAuth();
  const { stationId } = useParams();
  const [changeFlag, setChangeFlag] = useState(Date.now());
  const filterFlag = 'SUCCESSFUL_CHARGING_REQUESTS';

  function calculatedates(days) {
    let date = new Date();
    let last = new Date(date.getTime() - days * 24 * 60 * 60 * 1000);
    return (
      last.getFullYear() +
      '-' +
      last.toLocaleString('en-US', { month: '2-digit' }) +
      '-' +
      last.toLocaleString('en-US', { day: '2-digit' })
    );
  }

  const validationSchema = useMemo(
    () =>
      Yup.object({
        startDate: Yup.date()
          .label(t('LBLStartDate'))
          .typeError(t('LBLInvalidDate'))
          .required(t('LBLReqCockpit')),
        endDate: Yup.date()
          .typeError(t('LBLInvalidDate'))
          .label(t('LBLEndDate'))
          .min(Yup.ref('startDate'), i18n.t('LBLENdgStart'))
          .required(t('LBLEndDateReq')),
      }),
    [t, i18n.language]
  );

  const initialValues = {
    startDate: startDate,
    endDate: endDate,
  };

  const handleSubmit = (values, actions) => {
    setStartDate(values.startDate);
    setEndDate(values.endDate);
    setChangeFlag(new Date());
    actions.setSubmitting(false);
  };

  const getcharginstationInfoAndKpi = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.chargingStationInfoAndKPI(token, stationId);
      if (response?.data.data) {
        setChargingStation(response.data.data);
      } else {
        setChargingStation({});
      }
    } catch (exception) {
      setLoading(false);
    }
    setLoading(false);
  }, [token, stationId]);

  useEffect(() => {
    if (refreshTheData >= 0) {
      getcharginstationInfoAndKpi();
    }
    setRefreshTheData(0);
  }, [refreshTheData]);

  const handleStartDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setStartDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setStartDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('startDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setStartDate(newValue);
      setFieldValue('startDate', newValue);
      console.error('Invalid startDate input');
    }
  };
  const handleEndDateChange = (newValue, setFieldValue) => {
    if (
      newValue instanceof Date &&
      (!isNaN(newValue.getTime()) || !isNaN(newValue.toDate()))
    ) {
      setEndDate(format(newValue, 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue, 'yyyy-MM-dd'));
    } else if (newValue != null && !isNaN(newValue.toDate())) {
      setEndDate(format(newValue.toDate(), 'yyyy-MM-dd'));
      setFieldValue('endDate', format(newValue.toDate(), 'yyyy-MM-dd'));
    } else {
      setEndDate(newValue);
      setFieldValue('endDate', newValue);
      console.error('Invalid EndDate input');
    }
  };
  const handleCloseFeedbackParkingLot = useCallback(() => {
    getcharginstationInfoAndKpi();
  }, [getcharginstationInfoAndKpi]);

  return (
    <DetailsWrapper
      loading={loading}
      backButtonConfig={{
        label: t('backToStationOverview'),
        url: '/stationen',
      }}
      title={t('LBLChargingStation')}
      helmet={t('LBLStationOverview')}
      contract={
        <EditChargingStationInformation
          chargingStation={chargingStation}
          setRefreshTheData={setRefreshTheData}
          onCloseFeedback={handleCloseFeedbackParkingLot}
        />
      }
    >
      <Grid container direction="row" columnSpacing={1}>
        <Grid item xs={6}>
          <ChargingStationComponent chargingStation={chargingStation} />
        </Grid>
        <Grid item xs={6}>
          <CurrentStatusComponent chargingStation={chargingStation} />
        </Grid>
      </Grid>

      <Grid item xs={12} xl={3} py={1} display="flex" justifyContent="flex-end">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values, setFieldValue }) => (
            <Form noValidate>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    label={t('LBLStartDate')}
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    value={values.startDate}
                    onChange={(newValue) => {
                      handleStartDateChange(newValue, setFieldValue);
                    }}
                    renderInput={(params) => (
                      <Field
                        component={TextField}
                        {...params}
                        required
                        id="startDate"
                        name="startDate"
                        margin="none"
                        autoComplete="off"
                        helperText={errors.startDate}
                        error={errors.startDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <DatePicker
                    PaperProps={{ sx: { boxShadow: shadows[3] } }}
                    label={t('LBLEndDate')}
                    value={values.endDate}
                    onChange={(newValue) => {
                      handleEndDateChange(newValue, setFieldValue);
                    }}
                    maxDate={dayjs()}
                    renderInput={(params) => (
                      <Field
                        required
                        component={TextField}
                        {...params}
                        name="endDate"
                        margin="none"
                        id="endDate"
                        autoComplete="off"
                        helperText={errors.endDate}
                        error={errors.endDate}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: 'TT.MM.JJJJ',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={errors.startDate || errors.endDate}
                >
                  {t('BTNSubmit')}
                </Button>
              </Stack>
            </Form>
          )}
        </Formik>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Table
            startDate={startDate}
            endDate={endDate}
            filterParam={filterFlag}
            stationId={stationId}
            changeFlag={changeFlag}
          />
        </Grid>
      </Grid>
      <FailFeedback
        open={error}
        title={'Fehler'}
        severity="warning"
        message={
          <>
            <Typography align="center">{t('LBLSomethingwentWrong')}</Typography>
          </>
        }
        handleClose={() => {
          setError(false);
          setError204(false);
        }}
      />
      <FailFeedback
        open={error204}
        title={t('noinfoFound')}
        severity="warning"
        message={
          <>
            <Typography align="center">{t('LBLNoDataDC')}</Typography>
          </>
        }
        handleClose={() => {
          setError(false);
          setError204(false);
        }}
      />
    </DetailsWrapper>
  );
};

export default StationDetails;
