import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import api from '../../Middleware/api/api';
import { useAuth } from '../../services/contexts';
import { useTranslation } from 'react-i18next';
import { Add as AddIcon } from '@mui/icons-material';
import {
  Divider as MuiDivider,
  Grid,
  Button,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { spacing } from '@mui/system';
import DataTable from '../../components/DataTable';
import { PAGE_SIZE, MIN_PAGE_SIZE } from '../../services/utils/units-constants';
const Divider = styled(MuiDivider)(spacing);
import RFIDForm from './components/RFIDForm';
import StepperPopup from '../../components/StepperPopup';
import FailFeedback from '../../components/DataTable/Feedback';
import { createTheme } from '@mui/material/styles';
import LogoLoader from '../../components/LogoLoader';
import Feedback from '../../components/Feedback';

// eslint-disable-next-line no-unused-vars
const dataTableTheme = () =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="5"]': {
              '& > *': {
                justifyContent: 'center',
                textAlign: 'center',
              },
            },
            '&[data-colindex="1"]': {
              '& > *': {
                justifyContent: 'left',
                textAlign: 'left',
                paddingLeft: 12,
              },
            },
            '&[data-colindex="6"], &[data-colindex="7"]': {
              '& > *': {
                justifyContent: 'right',
                textAlign: 'right',
                paddingLeft: 12,
              },
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="4"]': {
              '& > *': {
                justifyContent: 'center',
                textAlign: 'center',
                paddingRight: 10,
              },
            },
          },
        },
      },
    },
  });

function RFIDS() {
  const { t } = useTranslation();

  const { token } = useAuth();
  const [rfIds, setRFIDs] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [fetchingData, setFetchingData] = React.useState(true);
  const [open, setOpen] = useState(false);
  const [submitOperator, setSubmitOperator] = useState(0);
  // declaring states for the betreiber anlegen form
  const [operatorPayload, setOperatorPayload] = useState({});
  const [canNextOperator, setCanNextOperator] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getAllRfIds(currentPage, pageSize);
  }, [pageSize, currentPage]);

  // get List of RFIDs
  const getAllRfIds = async (page, size) => {
    setFetchingData(true);
    const response = await api.getAllRFIDs(token, page, size);
    setTotalRecords(response?.data?.data?.totalNumberOfRecords);
    setRFIDs(response?.data?.data?.response || []);
    setFetchingData(false);
  };

  // Define columns
  const columns = [
    //   { name: 'id', label: 'ID' },
    { name: 'serialId', label: 'Serial ID' },
    { name: 'rfidTag', label: 'RFID Tag' },
  ];

  return (
    <React.Fragment>
      <Helmet title="Invoices" />
      <LogoLoader loading={fetchingData} />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('LBLRFIDMgmt')}
          </Typography>
        </Grid>
        <Grid item mb={6}>
          <Button
            spacing={1}
            container
            item
            xs={12}
            sx={{ marginLeft: 5 }}
            variant="contained"
            color="primary"
            onClick={() => {
              setOpen(true);
            }}
          >
            <AddIcon />
            {t('BTNAddRFID')}
          </Button>
        </Grid>
      </Grid>
      <RFIDForm
        isOpen={open}
        handleClose={() => setOpen(false)}
        submit={submitOperator}
        setPayload={async (val) => {
          setFetchingData(true);
          const res = await api.createRFID(token, val);
          setOpen(false);
          if (res?.status === 200) {
            setSuccess(true)
            getAllRfIds(currentPage, pageSize);
          } else {
            res?.error && setError(true);
          }
        }}
        setCanNext={setCanNextOperator}
        setSubmit={setSubmitOperator}
      />
      <Divider my={6} />
      <ThemeProvider
        theme={(appTheme) => ({
          ...appTheme,
          components: {
            ...appTheme.components,
            ...dataTableTheme(appTheme).components,
          },
        })}
      >
        <DataTable
          data={rfIds}
          columns={columns}
          // options={options}
          options={{
            textLabels: {
              body: {
                noMatch: fetchingData ? t('LBLLOading') : t('LBLNoData'),
              },
            },
            currentPage: currentPage,
            totalRecords: totalRecords,
            selectableRows: 'none',
          }}
          getData={(seletedPageSize, currentPageVal) => {
            setCurrentPage(currentPageVal);
            setPageSize(seletedPageSize);
          }}
        />
      </ThemeProvider>
      <FailFeedback
        open={error}
        title={t('mistake')}
        severity="warning"
        message={
          <>
            <Typography align="center">{t('LBLSomethingwentWrong')}</Typography>
          </>
        }
        handleClose={() => {
          setError(false);
          setPayload(null);
          setPayloadOne(null);
        }}
      />
      <Feedback
        open={success}
        title={t('LBLRFIDSuccess')}
        handleClose={() => {
          setSuccess(false);
        }}
      />
    </React.Fragment>
  );
}
export default RFIDS;
