/* eslint-disable no-constant-condition */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Slide,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Check } from 'react-feather';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { CancelOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7faa40',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#7faa40',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#7faa40',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#7faa40',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 20,
    height: 20,
    textAlign: 'center',
    padding: 1,
    verticalAlign: 'middle',
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon"></Check>
      ) : (
        <div className="QontoStepIcon-circle">
          {' '}
          <div
            style={{
              color: 'white',
              textAlign: 'center',
              verticalAlign: 'middle',
            }}
          >
            {icon}
          </div>
        </div>
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};
StepperPopup.propTypes = {};

function StepperPopup({
  isOpen,
  handleClose,
  title,
  setIsOpen,
  steps,
  widthInput,
}) {
  const [activeStep, setActiveStep] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [completed, setCompleted] = useState({});
  const { t } = useTranslation();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: { width: widthInput ? widthInput : 800, maxWidth: 'initial' },
        }}
      >
        <DialogTitle>
          {title}
          {true ? (
            <IconButton
              aria-label="close"
              onClick={() => {
                setIsOpen(false);
                setActiveStep(0);
              }}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={{ overflowX: 'hidden' }}>
          <Stepper
            sx={{ width: '100%' }}
            activeStep={activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {steps.map((step, index) => (
            <Slide
              appear={true}
              in={index === activeStep}
              direction={index === 0 ? 'right' : 'left'}
              key={index}
            >
              <Paper
                sx={{
                  display: index === activeStep ? 'block' : 'none',
                  padding: 5,
                }}
              >
                {step.component}
              </Paper>
            </Slide>
          ))}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ color: 'grey.700' }}
            color="inherit"
            startIcon={
              activeStep === 0 ? (
                <CancelOutlined />
              ) : (
                <ArrowBackIcon fontSize="small" />
              )
            }
            onClick={() => {
              if (activeStep === 0) setIsOpen(false);
              else setActiveStep((prev) => prev - 1);
            }}
          >
            {activeStep === 0 ? t('cancel') : t('back')}
          </Button>
          <Button
            variant="contained"
            id="nextStep"
            onClick={() => {
              steps[activeStep].nextAction.action();
              if (steps[activeStep].nextAction.canNext) handleNext();
              if (activeStep === steps.length - 1) setActiveStep(0);
            }}
            endIcon={steps[activeStep].nextAction.icon || <></>}
          >
            {steps[activeStep].nextAction.label}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default StepperPopup;
