import React from 'react';
import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import * as Yup from 'yup';
import { useMemo, useState, useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useTranslation } from 'react-i18next';
import { validators } from '../../../../services/utils/validators';
/**
 * @param {PropTypes.InferProps<UserCreationForm.propTypes>} props
 */
const UserCreationForm = ({
  payload,
  setPayload,
  confirmPayload,
  submit,
  setSubmit,
  setCanNext,
}) => {
  const { t } = useTranslation();
  const billingType = 'WITH_BILLING';
  const authType = 'APP';
  const [minStartDate, setMinStartDate] = useState('');

  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click();
    setSubmit(0);
    var today = new Date();
    var yyyy = today.getFullYear();
    setMinStartDate(
      yyyy + today.toLocaleString('en-US', { month: '2-digit' }) + '01'
    );
  }, [submit, minStartDate]);
  const initialValues = {
    firstName: payload?.firstName || '',
    lastName: payload?.lastName || '',
    email: payload?.email || '',
    street: payload?.contractAddress.street || '',
    careOf: payload?.contractAddress.careOf || '',
    city: payload?.contractAddress.city || '',
    zipCode: payload?.contractAddress.zipCode || '',
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        firstName: Yup.string().required(t('firstnamereq')).label(t('vernom')),
        lastName: Yup.string().required(t('lnameReq')).label(t('lastname')),
        email: Yup.string()
          .matches(validators.EMAIL, t('wrongEmail'))
          .email(t('wrongEmail'))
          .required(t('emailReq'))
          .label('E-Mail'),
        street: Yup.string().required(t('LBLNameReq')).label(t('lblStName')),
        city: Yup.string().required(t('LBLLocReq')).label(t('city')),
        zipCode: Yup.number()
          .typeError(t('NumericOnly'))
          .required(t('ZipcodeReq'))
          .label(t('PostalCode')),
        careOf: Yup.string().label('c/o'),
      }),
    [billingType, authType]
  );
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      contractAddress: {
        city: values.city,
        careOf: values.careOf,
        street: values.street,
        zipCode: values.zipCode,
        countryCode: 'DE', // always DE by default for Germany
      },
    };

    setPayload(payload);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, false);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, false, false);
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ touched, isValid }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}
            <Stack my={5} direction="row" alignItems="center" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="vorname"
                name="firstName"
                label={t('vernom')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="nachname"
                name="lastName"
                label={t('lastname')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="straße-und-nummer"
                name="street"
                label={t('lblStName')}
                required
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="c-o"
                name="careOf"
                label="c/o"
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                required
                margin="none"
                component={TextField}
                id="postleitzahl"
                name="zipCode"
                label={t('PostalCode')}
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
              <Field
                required
                fullWidth
                margin="none"
                component={TextField}
                id="ort"
                name="city"
                label={t('city')}
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
              />
            </Stack>
            <Stack mb={5} direction="row" spacing={8}>
              <Field
                fullWidth
                margin="none"
                component={TextField}
                id="e-mail"
                name="email"
                label="E-Mail"
                InputProps={{
                  title: 'Bitte füllen Sie dieses Feld aus',
                }}
                required
              />
              <span style={{ width: '100%' }} aria-hidden></span>
            </Stack>

            <Button sx={{ display: 'none' }} id="sub" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

UserCreationForm.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayload: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserCreationForm;
