import React, { useCallback, useState } from 'react';
import { formatDate } from '../../services/utils/helpers';
import { Item, Spacer, View } from './styles';
import { CardContent, Typography } from '@mui/material';
import {
  formatCurrency,
  formatDecimal,
} from '../../services/utils/format-numbers';
import IconButton from '@mui/material/IconButton';
import { InsertDriveFile } from '@mui/icons-material';
import { GERMAN_DATE_FORMAT } from '../../services/utils/date-format-constants';
import { PER_KWH, PER_MONTH } from '../../services/utils/units-constants';
import { useTranslation } from 'react-i18next';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from '../Users/components/CreateUser/LightTooltip';

export default function ContractComponent({ contract }) {
  const { t } = useTranslation();
  const contractUrl = `${window._env_.REACT_APP_CONTRACT_MANGEMENT_API}/pdf/${contract?.contractKey}?verificationId=${contract?.contractId}`;
  const vertragis = {
    Ladevorgangsgebühr:
      contract?.billingType === 'WITH_BILLING'
        ? `${formatDecimal(contract?.contractInfo?.fixTariffPerChargingRequest)} €/Ladevorgang`
        : null,
    Bereitstellungsgebühr:
      contract?.billingType === 'WITH_BILLING'
        ? `${formatDecimal(contract?.contractInfo?.onboardingFee)} €`
        : null,
    Zeitraum:
      contract?.contractInfo?.startDate &&
      formatDate(contract?.contractInfo?.startDate, GERMAN_DATE_FORMAT) +
        '-' +
        (contract?.contractInfo?.endDate &&
        contract?.contractInfo?.endDate !== null
          ? formatDate(contract?.contractInfo?.endDate, GERMAN_DATE_FORMAT)
          : t('unlimited')),
    NutzungsUndLadestromvertrag:
      contract?.pdfAvailable === true ? contract?.billingType : null,
    Nutzungstarif:
      contract?.billingType === 'WITH_BILLING'
        ? formatCurrency(contract?.contractInfo?.fixTariff) + PER_MONTH
        : null,
    Ladestromtarif:
      contract?.billingType === 'WITH_BILLING'
        ? formatCurrency(contract?.contractInfo?.varTariff) + PER_KWH
        : null,
  };
  const isPdfWithBilling =
    vertragis.NutzungsUndLadestromvertrag === 'WITH_BILLING' ? true : false;
  const renderKey = useCallback(
    (key) => {
      switch (key) {
        case 'NutzungsUndLadestromvertrag':
          return contract?.pdfAvailable === true ? t('chargincontract') : null;
        case 'Nutzungstarif':
          return contract?.billingType === 'WITH_BILLING' ? t('tarif') : null;
        case 'Ladestromtarif':
          return contract?.billingType === 'WITH_BILLING'
            ? t('chargintarif')
            : null;
        case 'Ladevorgangsgebühr':
          return contract?.billingType === 'WITH_BILLING'
            ? t('fixTariffPerChargingRequest')
            : null;
        case 'Bereitstellungsgebühr':
          return contract?.billingType === 'WITH_BILLING'
            ? t('onboardingFee')
            : null;
        case 'Zeitraum':
          return t('lblPeriod');
        default:
          return key;
      }
    },
    [contract]
  );

  const renderDates = useCallback(() => {
    let valueOfDates = '';
    if (contract?.contractChangeTariff != null) {
      valueOfDates =
        t('newTariffDate') +
        `:- ${formatDate(
          contract?.contractChangeTariff?.newTariffDate,
          GERMAN_DATE_FORMAT
        )}, ` +
        t('newTariffCancellationDate') +
        `:- ${formatDate(
          contract?.contractChangeTariff?.newTariffCancellationDate,
          GERMAN_DATE_FORMAT
        )} `;
    }
    return valueOfDates;
  }, [contract]);
  const rendervarTariffSecond = useCallback(() => {
    let valueOfTariff = '';
    if (contract?.contractChangeTariff != null) {
      valueOfTariff =
        t('textVarTariff') +
        `:- ${
          formatCurrency(contract?.contractChangeTariff?.varTariff) + PER_KWH
        } ` +
        t('applicableFrom') +
        `${formatDate(
          contract?.contractChangeTariff?.newTariffDate,
          GERMAN_DATE_FORMAT
        )}`;
    }
    return valueOfTariff;
  }, [contract]);

  const rendervarTariff = useCallback(() => {
    let valueOfTariff = '';
    if (contract?.contractChangeTariff != null) {
      valueOfTariff =
        t('textFixTariff') +
        `:- ${
          formatCurrency(contract?.contractChangeTariff?.fixTariff) + PER_MONTH
        } ` +
        t('applicableFrom') +
        `${formatDate(
          contract?.contractChangeTariff?.newTariffDate,
          GERMAN_DATE_FORMAT
        )}`;
    }
    return valueOfTariff;
  }, [contract]);
  const opendatesToolTip = () => () => setDatesToolTip(true);
  const closeDateTooltip = () => setDatesToolTip(false);
  const [datesToolTip, setDatesToolTip] = useState(false);
  const openvarTariffToolTip = () => () => setvarTariffToolTip(true);
  const closevarTariffTooltip = () => setvarTariffToolTip(false);
  const [varTariffToolTip, setvarTariffToolTip] = useState(false);
  const openvarTariffSecondToolTip = () => () =>
    setvarTariffSecondToolTip(true);
  const closevarTariffSecondTooltip = () => setvarTariffSecondToolTip(false);
  const [varTariffSecondToolTip, setvarTariffSecondToolTip] = useState(false);
  return (
    <View>
      <CardContent>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          {t('lblTerms')}
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item>
            <Typography fontWeight="fontWeightMedium">
              {renderKey('Zeitraum')}
              {contract?.contractChangeTariff != null ? (
                <InfoIcon
                  onClick={opendatesToolTip(true)}
                  color="action"
                  fontSize="small"
                  cl
                  sx={{
                    position: 'relative',
                    display: 'flex-row',
                    ml: 1,
                    mt: 1,
                  }}
                />
              ) : null}
            </Typography>
            <Typography
              sx={{
                maxWidth: 200,
                whiteSpace: 'pre-wrap',
                lineBreak: 'normal',
              }}
              align="right"
              fontWeight="fontWeightRegular"
            >
              {['string', 'number'].includes(typeof vertragis.Zeitraum)
                ? vertragis.Zeitraum
                : vertragis.Zeitraum?.join(', ') || null}
            </Typography>
          </Item>
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item>
            <Typography fontWeight="fontWeightMedium">
              {renderKey('NutzungsUndLadestromvertrag')}
            </Typography>
            <Typography
              sx={{
                maxWidth: 200,
                whiteSpace: 'pre-wrap',
                lineBreak: 'normal',
              }}
              align="right"
              fontWeight="fontWeightRegular"
            >
              {isPdfWithBilling ? (
                <IconButton
                  component={'a'}
                  target="_blank"
                  href={contractUrl}
                  color="primary"
                >
                  <InsertDriveFile />
                </IconButton>
              ) : ['string', 'number'].includes(
                  typeof vertragis.NutzungsUndLadestromvertrag
                ) ? (
                vertragis.NutzungsUndLadestromvertrag
              ) : (
                vertragis.NutzungsUndLadestromvertrag?.join(', ') || null
              )}
            </Typography>
          </Item>
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item>
            <Typography fontWeight="fontWeightMedium">
              {renderKey('Nutzungstarif')}
              {contract?.contractChangeTariff != null ? (
                <InfoIcon
                  onClick={openvarTariffToolTip(true)}
                  color="action"
                  fontSize="small"
                  cl
                  sx={{
                    position: 'relative',
                    display: 'flex-row',
                    ml: 1,
                    mt: 1,
                  }}
                />
              ) : null}
            </Typography>
            <Typography
              sx={{
                maxWidth: 200,
                whiteSpace: 'pre-wrap',
                lineBreak: 'normal',
              }}
              align="right"
              fontWeight="fontWeightRegular"
            >
              {['string', 'number'].includes(typeof vertragis.Nutzungstarif)
                ? vertragis.Nutzungstarif
                : vertragis.Nutzungstarif?.join(', ') || null}
            </Typography>
          </Item>
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item>
            <Typography fontWeight="fontWeightMedium">
              {renderKey('Ladestromtarif')}
              {contract?.contractChangeTariff != null ? (
                <InfoIcon
                  onClick={openvarTariffSecondToolTip(true)}
                  color="action"
                  fontSize="small"
                  cl
                  sx={{
                    position: 'relative',
                    display: 'flex-row',
                    ml: 1,
                    mt: 1,
                  }}
                />
              ) : null}
            </Typography>
            <Typography
              sx={{
                maxWidth: 200,
                whiteSpace: 'pre-wrap',
                lineBreak: 'normal',
              }}
              align="right"
              fontWeight="fontWeightRegular"
            >
              {['string', 'number'].includes(typeof vertragis.Ladestromtarif)
                ? vertragis.Ladestromtarif
                : vertragis.Ladestromtarif?.join(', ') || null}
            </Typography>
          </Item>
        </Typography>

        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item>
            <Typography fontWeight="fontWeightMedium">
              {renderKey('Ladevorgangsgebühr')}
            </Typography>
            <Typography
              sx={{
                maxWidth: 200,
                whiteSpace: 'pre-wrap',
                lineBreak: 'normal',
              }}
              align="right"
              fontWeight="fontWeightRegular"
            >
              {['string', 'number'].includes(
                typeof vertragis.Ladevorgangsgebühr
              )
                ? vertragis.Ladevorgangsgebühr
                : vertragis.Ladestromtarif?.join(', ') || null}
            </Typography>
          </Item>
        </Typography>
        <Spacer mb={4} />
        <Typography variant="body2" component="div" gutterBottom>
          <Item>
            <Typography fontWeight="fontWeightMedium">
              {renderKey('Bereitstellungsgebühr')}
            </Typography>
            <Typography
              sx={{
                maxWidth: 200,
                whiteSpace: 'pre-wrap',
                lineBreak: 'normal',
              }}
              align="right"
              fontWeight="fontWeightRegular"
            >
              {['string', 'number'].includes(
                typeof vertragis.Bereitstellungsgebühr
              )
                ? vertragis.Bereitstellungsgebühr
                : vertragis.Bereitstellungsgebühr?.join(', ') || null}
            </Typography>
          </Item>
        </Typography>
        <LightTooltip
          open={datesToolTip}
          onClose={closeDateTooltip}
          placement="top-start"
          title={renderDates()}
        ></LightTooltip>
        <LightTooltip
          open={varTariffToolTip}
          onClose={closevarTariffTooltip}
          placement="top-start"
          title={rendervarTariff()}
        ></LightTooltip>
        <LightTooltip
          open={varTariffSecondToolTip}
          onClose={closevarTariffSecondTooltip}
          placement="top-start"
          title={rendervarTariffSecond()}
        ></LightTooltip>
      </CardContent>
    </View>
  );
}
