import React, { useEffect } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import * as yup from 'yup';
import { useUser } from '../../../services/contexts';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

function OperatorForm({ setPayload, submit, setCanNext, setSubmit }) {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    cpoName: yup.string().required(t('cpoNameReq')),
    city: yup.string().required(t('LBLLocReq')),
    street: yup.string().required(t('LBLNameReq')),
    zipCode: yup.string().required(t('ZipcodeReq')),
    careOf: yup.string(),
  });

  const { user } = useUser();
  const CUSTOMER_ID = user?.customer_id;
  const CUSTOMER_TYPE = user?.customer_type;

  useEffect(() => {
    if (submit > 0) document.getElementById('sub').click();
    setSubmit(0);
  }, [submit]);

  const formik = useFormik({
    initialValues: {
      cpoName: '',
      city: '',
      countryCode: '',
      street: '',
      zipCode: '',
      careOf: '',
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (values) => {
      setPayload({
        customerId: CUSTOMER_ID,
        customerType: CUSTOMER_TYPE,
        cpoName: values.cpoName,
        cpoAddress: {
          street: values.street,
          careOf: values.careOf,
          zipCode: values.zipCode,
          city: values.city,
        },
      });
    },
  });
  useEffect(() => {
    setCanNext(formik.isValid && formik.touched);
  }, [formik]);

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate>
        <TextField
          margin="dense"
          id="name"
          label={t('opName')}
          variant="outlined"
          name={'cpoName'}
          required
          value={formik.values.cpoName}
          onChange={formik.handleChange}
          error={formik.touched.cpoName && Boolean(formik.errors.cpoName)}
          helperText={formik.touched.cpoName && formik.errors.cpoName}
          fullWidth
          my={2}
        />
        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              margin="dense"
              id="streetAndNumber"
              label={t('lblStName')}
              variant="outlined"
              required
              fullWidth
              name={'street'}
              my={2}
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.touched.street && Boolean(formik.errors.street)}
              helperText={formik.touched.street && formik.errors.street}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              margin="dense"
              id="flatNr"
              label="c/o"
              variant="outlined"
              fullWidth
              my={2}
              name={'careOf'}
              value={formik.values.careOf}
              onChange={formik.handleChange}
              error={formik.touched.careOf && Boolean(formik.errors.careOf)}
              helperText={formik.touched.careOf && formik.errors.careOf}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6}>
            <TextField
              margin="dense"
              id="zipCode"
              label={t('PostalCode')}
              variant="outlined"
              required
              fullWidth
              my={2}
              name={'zipCode'}
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
              helperText={formik.touched.zipCode && formik.errors.zipCode}
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              margin="dense"
              id="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              label={t('city')}
              variant="outlined"
              required
              fullWidth
              my={2}
            />
          </Grid>
        </Grid>
        {/**
         * this button is hidden and used to trigger submit from outside of the component
         */}
        <Button sx={{ display: 'none' }} id="sub" type="submit"></Button>
      </form>
    </>
  );
}
export default OperatorForm;
