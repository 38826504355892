import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
function Popup({
  open,
  title,
  message,
  handleClose,
  handleAction,
  closeButtonBgColour,
  closeButtonTxtColour,
}) {
  const { typography } = useTheme();
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        align="center"
        fontWeight={700}
        fontSize={typography.h5.fontSize}
        pt={8}
      >
        {title}
      </DialogTitle>
      <DialogContent align="center" sx={{ width: 600, mx: 'auto', my: 3 }}>
        {message}
      </DialogContent>
      <DialogActions sx={{ pb: 8, justifyContent: 'center' }}>
        <Button
          variant="contained"
          sx={{
            px: 12,
            bgcolor: closeButtonBgColour,
            color: closeButtonTxtColour,
          }}
          onClick={handleClose}
        >
          {t('close')}
        </Button>
        <Button variant="contained" sx={{ px: 12 }} onClick={handleAction}>
          {t('yessave')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Popup.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Popup;
