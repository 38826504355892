import React, { useMemo, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import Stack from '@mui/material/Stack';
import { useAuth } from '../../services/contexts/AuthContext';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import api from '../../Middleware/api/api';
import {
  Button,
  Divider as MuiDivider,
  Grid,
  Menu,
  MenuItem,
  Paper as MuiPaper,
  ThemeProvider,
  Typography,
  TableCell,
} from '@mui/material';
import { Add as AddIcon, ArrowForward } from '@mui/icons-material';
import { spacing } from '@mui/system';
import NutzerAnlegen from './components/CreateUser';
import { createTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import DataTable from '../../components/DataTable';
import StepperPopup from '../../components/StepperPopup';
import MultipleBillingStatusEdit from '../../components/multipleEditDialog/MultipleBillingStatusEdit';
import Feedback from '../../components/DataTable/Feedback';
import LogoLoader from '../../components/LogoLoader';
import { PAGE_SIZE } from '../../services/utils/units-constants';
var rows = [];

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const dataTableTheme = (appTheme) =>
  createTheme({
    components: {
      MuiPaper: {
        variants: [
          {
            props: {
              variant: 'elevation',
            },
            style: {
              boxShadow: shadows[3],
            },
          },
        ],
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            width: 300,
            boxShadow: shadows[3],
            backgroundColor: appTheme.palette.background.paper,
          },
          title: {
            lineHeight: 3,
          },
          header: {
            marginBottom: 0,
          },
          gridListTile: {
            styleOverrides: {
              paddingLeft: '25px',
              marginLeft: '25px',
            },
            sx: {
              pl: 28,
            },
          },

          reset: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: 4,
          },
          resetLink: {
            button: {
              variants: [
                {
                  props: {
                    variant: 'outlined',
                  },
                },
              ],
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            paddingLeft: '8px',
            '&[data-colindex="5"], &[data-colindex="8"]': {
              '& > *': {
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
              },
              '& .MuiButton-root': {
                margin: 'auto',
              },
              '&[data-colindex="4"], &[data-colindex="1"], &[data-colindex="2"], &[data-colindex="3"], &[data-colindex="4", &[data-colindex="6"]':
                {
                  '& > *': {
                    display: 'flex',
                    justifyContent: 'left',
                    textAlign: 'left',
                  },
                  '& .MuiButton-root': {
                    margin: 'auto',
                    paddingLeft: '8px',
                  },
                },
              '&[data-colindex="7"]': {
                '& > *': {
                  display: 'flex',
                  justifyContent: 'right',
                  textAlign: 'right',
                },
                '& .MuiButton-root': {
                  margin: 'auto',
                },
              },
            },
          },
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'filled',
          margin: 'dense',
          size: 'medium',
          fullWidth: false,
          sx: {
            ml: 2,
            width: '230px',
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          margin: 'dense',
        },
      },
    },
  });
function EnhancedTable({ apiCallCount, handleReset }) {
  const { t, i18n } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);
  const [fetchingData, setFetchingData] = React.useState(true);
  const [operatorContract, setOperatorContract] = React.useState([]);
  const { token } = useAuth();

  useEffect(() => {
    if (apiCallCount > 0) {
      getUserData(pageSize, currentPage);
    }
  }, [apiCallCount]);

  useEffect(() => {
    getUserData(pageSize, currentPage);
  }, [currentPage, pageSize]);

  const getUserData = (seletedPageSize, currentPageVal) => {
    try {
      setFetchingData(true);
      api.fetchOperatorContracts(token, currentPageVal, seletedPageSize).then(
        (response) => {
          if (response.data.data == null) {
            rows = [];
            setOperatorContract([]);
          } else {
            handleReset(0);
            setTotalRecords(response?.data?.data?.totalNumberOfRecords);
            rows = response?.data?.data?.response;
            handleReset(0);
            const rowsWithDetail = rows.map((row) => ({
              ...row,
              details: row,
              contractStatus: row.contractStatus,
              billingStatus:
                row.billingStatus === 'PAID'
                  ? 'Bezahlt'
                  : row.billingStatus === 'UNPAID'
                    ? 'Offen'
                    : null,
            }));

            setOperatorContract(rowsWithDetail);
            setFetchingData(false);
          }
        },
        () => {
          rows = [];
          setOperatorContract(rows);
          setFetchingData(false);
        }
      );
    } catch (exception) {
      rows = [];
      setOperatorContract(rows);
      setFetchingData(false);
    }
  };
  useEffect(() => {
    setSelected(selectedRows.map((index) => operatorContract[index]));
  }, [selectedRows]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState([]);
  const [submit, setSubmit] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [showErrorFeedback, setShowErrorFeedback] = useState(false);
  const [showNoEntriesFeedback, setShowNoEntriesFeedback] = useState(false);
  const [showNullBillingStatusFeedback, setShowNullBillingStatusFeedback] =
    useState(false);
  const [
    showMultiNullBillingStatusFeedback,
    setShowMultiNullBillingStatusFeedback,
  ] = useState(false);
  const [checkStatusChange, setCheckStatusChange] = useState(false);

  const [openElem, setOpenElem] = useState(null);
  const [anchorIconButton, setAnchorIconButton] = useState(null);
  const handleCloseIconMenu = () => {
    setOpenElem(null);
    setAnchorIconButton(null);
  };

  function triggerMultipleBillingStatusEdit() {
    if (selectedRows.length > 0) {
      if (selectedRows.filter((row) => row.billingStatus == null).length > 0) {
        setAnchorEl(null);
        setShowMultiNullBillingStatusFeedback(true);
        setSelectedRows([]);
      } else setIsOpen(true);
    } else {
      setAnchorEl(null);
      setShowNoEntriesFeedback(true);
    }
  }

  const columns = useMemo(() => [
    {
      name: 'nutzerId',
      options: {
        display: false,
        filter: false,
        customBodyRender: (value) => {
          return value || <>-</>;
        },
      },
    },
    {
      name: 'endCustomerId',
      label: t('LBLCustomerNum'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="left">{value}</Typography>;
        },
        customHeadRender: () => (
          <TableCell
            align="left"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLCustomerNum')}
          </TableCell>
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="left">{value}</Typography>;
        },
        setCellHeaderProps: () => {
          return { align: 'start' };
        },
        setCellProps: () => {
          return { align: 'left' };
        },
      },
    },
    {
      name: 'location',
      label: t('LBLLocation'),
      options: {
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
        customBodyRender: (value) => {
          return <Typography align="left">{value}</Typography>;
        },
        customHeadRender: () => (
          <TableCell
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLLocation')}
          </TableCell>
        ),
      },
    },
    {
      name: 'parkingLotNumbers',
      label: t('LBLParkingLotNum'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="left">{value.join(', ')}</Typography>;
        },
        customHeadRender: () => (
          <TableCell
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLParkingLotNum')}
          </TableCell>
        ),
      },
    },
    {
      name: 'contractStatusMapping',
      label: t('LBLUserStatus'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (!value) return null;
          return (
            <Typography align="center" color={value.colourCode}>
              {t(value.enumName)}
            </Typography>
          );
        },
        customHeadRender: () => (
          <TableCell
            variant="head"
            sx={{
              textAlign: 'center',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLUserStatus')}
          </TableCell>
        ),
      },
    },
    {
      name: 'details',
      label: ' ',
      options: {
        sort: false,
        filter: false,
        customBodyRender(value) {
          return (
            <>
              <Menu
                anchorEl={anchorIconButton}
                open={openElem === value}
                key={value}
                onClose={handleCloseIconMenu}
                MenuListProps={{
                  'aria-labelledby': 'edit-selected',
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem
                  key={value}
                  onClick={() => {
                    if (value.billingStatus) {
                      setSelected([value]);
                      setIsOpen(true);
                      handleCloseIconMenu();
                    } else {
                      setShowNullBillingStatusFeedback(true);
                      handleCloseIconMenu();
                    }
                  }}
                >
                  <Typography align="center" sx={{ width: '100%' }}>
                    {t('LBLChangePayment')}
                  </Typography>
                </MenuItem>
              </Menu>
              <Button
                component={NavLink}
                to={`/nutzer/${value.endCustomerId}/`}
                variant="text"
              >
                Details
              </Button>
            </>
          );
        },
      },
    },
  ]);

  return (
    <>
      <LogoLoader loading={fetchingData} />
      <ThemeProvider
        theme={(appTheme) => ({
          ...appTheme,
          components: {
            ...appTheme.components,
            ...dataTableTheme(appTheme).components,
          },
        })}
      >
        <Stack
          alignItems="flex-end"
          spacing={8}
          position="relative"
          width={{
            sm: '100%',
            md: 'calc(100vw - 260px - 40px)',
            lg: 'calc(100vw - 260px - 64px)',
          }}
        >
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'edit-selected',
            }}
            sx={{
              textAlign: 'center',
            }}
          >
            <MenuItem onClick={triggerMultipleBillingStatusEdit}>
              {t('LBLChangePayment')}
            </MenuItem>
          </Menu>
          <Paper
            variant="outlined"
            sx={{
              width: '100%',
              '& .MuiTableCell-head:not(:nth-of-type(2)) button': {
                mx: 0,
              },
            }}
          >
            <DataTable
              data={operatorContract}
              columns={columns}
              options={{
                textLabels: {
                  body: {
                    noMatch: fetchingData ? t('LBLLOading') : t('LBLnoUsers'),
                  },
                },
                onRowSelectionChange: (_props, allRowsSelected) => {
                  setSelectedRows(
                    // eslint-disable-next-line no-unused-vars
                    allRowsSelected.map(({ index, dataIndex }) => dataIndex)
                  );
                },
                rowsSelected: selectedRows,
                filter: false,
                search: false,
                selectableRows: true,
                currentPage: currentPage,
                totalRecords: totalRecords,
              }}
              getData={(seletedPageSize, currentPageVal) => {
                setCurrentPage(currentPageVal);
                setPageSize(seletedPageSize);
              }}
            />
          </Paper>
        </Stack>
      </ThemeProvider>
      <StepperPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={t('LBLChangePayment')}
        steps={[
          {
            label: t('LBLChangeEnter'),
            component: (
              <MultipleBillingStatusEdit
                data={selected}
                payload={payload}
                setPayload={setPayload}
                submit={submit}
                setShowFeedback={setShowFeedback}
                setShowErrorFeedback={setShowErrorFeedback}
                checkStatusChange={checkStatusChange}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setCheckStatusChange(true);
              },
              canNext:
                !payload.filter((value) => value?.changed === false).length > 0,
            },
          },
          {
            label: t('LBLConfirmChanges'),
            component: (
              <MultipleBillingStatusEdit
                data={selected}
                payload={payload}
                submit={submit}
                setPayload={setPayload}
                setShowFeedback={setShowFeedback}
                setShowErrorFeedback={setShowErrorFeedback}
                isReadOnly
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              icon: <></>,
              action: () => {
                setSubmit((prevState) => prevState + 1);
                setIsOpen(false);
              },
              canNext: false,
            },
          },
        ]}
      />
      <Feedback
        open={showFeedback}
        title={t('LBLPStatusChanged')}
        message={
          <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
            {t('paymentUpdated')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setPayload([]);
          setSelected([]);
          setShowFeedback(false);
        }}
      />
      <Feedback
        open={showErrorFeedback}
        message={
          <Typography
            variant="subtitle1"
            fontWeight={600}
            sx={{ width: 400, mx: 10 }}
          >
            {t('LBLServerAgain')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setPayload([]);
          setSelected([]);
          setShowErrorFeedback(false);
        }}
      />
      <Feedback
        open={showNoEntriesFeedback}
        message={
          <Typography
            variant="subtitle1"
            fontWeight={400}
            sx={{ width: 400, mx: 10 }}
          >
            {t('LBLSelectOneUser')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setPayload([]);
          setSelected([]);
          setShowNoEntriesFeedback(false);
        }}
      />
      <Feedback
        open={showNullBillingStatusFeedback}
        title={t('LBLWrongSelection')}
        message={
          <Typography
            variant="subtitle1"
            fontWeight={400}
            sx={{ width: 400, mx: 10 }}
          >
            {t('LBLNoInvoice')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setPayload([]);
          setSelected([]);
          setShowNullBillingStatusFeedback(false);
        }}
      />
      <Feedback
        open={showMultiNullBillingStatusFeedback}
        title={t('LBLWrongSelection')}
        message={
          <Typography
            variant="subtitle1"
            fontWeight={400}
            sx={{ width: 400, mx: 10 }}
          >
            {t('LBLNoInvoiceUser')}
          </Typography>
        }
        severity="warning"
        handleClose={() => {
          setPayload([]);
          setSelected([]);
          setShowMultiNullBillingStatusFeedback(false);
        }}
      />
    </>
  );
}

function Users() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [apiCallCount, setApiCallCount] = useState(0);
  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setApiCallCount(1);
    setOpen(false);
  };

  const handleResetcount = (input) => {
    setApiCallCount(0);
  };
  return (
    <React.Fragment>
      <Helmet title="Nutzers" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('LBLUserMgmt')}
          </Typography>
        </Grid>
        <Grid item mb={6}>
          <div>
            <Button variant="contained" color="primary" onClick={openModal}>
              <AddIcon />
              {t('LBLCreateUser')}
            </Button>
            <NutzerAnlegen
              paddingLeft={10}
              style={{ backgroundColor: 'red' }}
              open={open}
              handleClose={closeModal}
              setOpen={setOpen}
            />
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable
            apiCallCount={apiCallCount}
            handleReset={handleResetcount}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Users;
