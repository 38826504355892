/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import {
  Tooltip,
  IconButton as MuiIconButton,
  CircularProgress,
} from '@mui/material';
import { useUser } from '../../services/contexts/UserContext';
import { userService } from '../../services/utils/axios';
import { useAuth } from '../../services/contexts/AuthContext';
import { PowerSettingsNew as PowerIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function NavbarUserDropdown() {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  const [urlVal, setUrlVal] = useState(window.location.href);

  const [showTranslator, setShowTranslator] = useState(false);

  const changeLanguageHandler = (languageValue) => {
    i18n.changeLanguage(languageValue);
    setCurrentLang(languageValue);
  };
  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const { authenticate } = useAuth();
  const { user } = useUser();
  const [loggingOut, setLoggingOut] = React.useState(false);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const logout = () => {
    setLoggingOut(true);
    userService
      .get(`/logout/${user.sub}`)
      .then(authenticate)
      .then(() => localStorage.removeItem('accessToken'))
      .finally(() => setLoggingOut(false));
  };
  useEffect(() => {
    if (
      window.location.href.includes('localhost') ||
      window.location.href.includes('dev') ||
      window.location.href.includes('int')
    ) {
      setShowTranslator(true);
    }
  }, [urlVal]);
  return (
    <React.Fragment>
      {showTranslator == true ? (
        <Tooltip title="Switch language">
          <div
            style={{
              float: 'left',
              textAlign: 'left',
              marginRight: '10px',
              marginTop: '10px',
              padding: '2px',
              backgroundColor: 'transparent',
            }}
          >
            <div
              onClick={() => changeLanguageHandler('en')}
              style={{
                float: 'right',
                cursor: 'pointer',
                margin: '5px',
                color: currentLang == 'en' ? '#7faa40' : 'grey',
              }}
            >
              En
            </div>
            <div
              onClick={() => changeLanguageHandler('de')}
              style={{
                float: 'right',
                cursor: 'pointer',
                margin: '5px',
                color: currentLang != 'en' ? '#7faa40' : 'grey',
              }}
            >
              De
            </div>
          </div>
        </Tooltip>
      ) : (
        <div></div>
      )}

      <Tooltip title="Abmelden">
        {loggingOut ? (
          <div>
            <CircularProgress size={20} sx={{ color: 'action.disabled' }} />
          </div>
        ) : (
          <IconButton
            aria-owns={anchorMenu ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={logout}
            size="large"
          >
            <PowerIcon />
          </IconButton>
        )}
      </Tooltip>
    </React.Fragment>
  );
}

export default NavbarUserDropdown;
