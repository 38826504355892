/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import { useAuth } from '../../services/contexts';
import {
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
  Typography,
} from '@mui/material';
import api from '../../Middleware/api/api';
import { formatCurrency } from '../../services/utils/format-numbers';

const cols = [
  {
    name: 'endCustomerId',
    label: 'Kundennummer',
  },
  {
    name: 'name',
    label: 'Name',
  },
  {
    name: 'billingNumber',
    label: 'Rechnungs-Nr.',
  },
  {
    name: 'totalTaxedCost',
    label: 'Rechnungsendbetrag',
    options: {
      customBodyRender: (value) => {
        return <Typography align="center">{formatCurrency(value)}</Typography>;
      },
    },
  },
  {
    label: 'Zahlungsstatus',
    name: 'billingStatus',
    type: 'select',
    selectConfig: {
      label: 'Ladestationstatus',
      values: [
        {
          value: 'PAID',
          label: 'Bezahlt',
        },
        {
          value: 'UNPAID',
          label: 'Offen',
        },
      ],
    },
  },
];
function MultipleBillingStatusEdit({
  setShowFeedback,
  data,
  isReadOnly,
  payload,
  setPayload,
  submit,
  checkStatusChange,
  setShowErrorFeedback,
}) {
  const { token } = useAuth();
  const { status } = useTheme();
  useEffect(() => {
    setPayload(
      data.map((value) => ({
        billingNumber: value.billingNumber || value.billNumber,
        billingStatus: value.billingStatus,
        changed: false,
      }))
    );
  }, [data]);
  function handleChange(event) {
    setPayload((prevState) =>
      prevState.map((value, index) => {
        return {
          ...value,
          billingStatus: event.target.value === 'PAID' ? 'Bezahlt' : 'Offen',
          changed: event.target.value !== data[index].billingStatus,
        };
      })
    );
  }
  useEffect(() => {
    if (submit > 0) {
      payload.map(
        (bill) =>
          (bill.billingStatus =
            bill.billingStatus === 'Bezahlt' ? 'PAID' : 'UNPAID')
      );
      api.changeBillingStatus(token, payload).then(
        // eslint-disable-next-line no-unused-vars
        (response) => setShowFeedback(true),
        // eslint-disable-next-line no-unused-vars
        (error) => {
          setShowErrorFeedback(true);
        }
      );
    }
  }, [submit]);

  return (
    <>
      <TableContainer component={Paper} variant="outlined">
        <Table
          aria-label="simple table"
          sx={({ palette, typography }) => ({
            '& .MuiTableHead-root .MuiTableCell-root': {
              fontWeight: 600,
              fontSize: typography.subtitle2.fontSize,
            },

            '& .MuiTableRow-root .MuiTableCell-root': {
              width: 140,
              borderLeft: `1px solid ${palette.divider}`,
              borderRight: `1px solid ${palette.divider}`,
            },
          })}
        >
          <TableHead>
            <TableRow>
              {cols?.map((column) => (
                <TableCell
                  key={column.name}
                  align={column.name === 'connectorStatus' ? 'center' : 'left'}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              <TableRow>
                {cols?.map((col, index) => {
                  let statusRender =
                    payload[rowIndex]?.billingStatus === 'Bezahlt'
                      ? 'PAID'
                      : 'UNPAID';
                  if (col.type === 'select') {
                    if (isReadOnly) {
                      return (
                        <TableCell
                          sx={{ color: status[statusRender?.toLowerCase()] }}
                          align={'center'}
                        >
                          {payload[rowIndex]?.billingStatus}
                        </TableCell>
                      );
                    }
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell align={'center'}>
                        <TextField
                          select
                          label={' '}
                          variant="standard"
                          margin="dense"
                          sx={{ textAlign: 'center' }}
                          id={`field-${index}`}
                          inputProps={{
                            'data-id': index,
                          }}
                          name={`field-${rowIndex}`}
                          value={statusRender || ''}
                          error={
                            !payload[rowIndex]?.changed && checkStatusChange
                          }
                          helperText={
                            !payload[rowIndex]?.changed && checkStatusChange
                              ? 'Feld nicht geändert'
                              : ''
                          }
                          onChange={handleChange}
                        >
                          {col.selectConfig?.values.map((value, valIndex) => (
                            <MenuItem key={valIndex + 1} value={value.value}>
                              {value.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </TableCell>
                    );
                  }

                  // eslint-disable-next-line react/jsx-key
                  return <TableCell>{row[col.name]}</TableCell>;
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default MultipleBillingStatusEdit;
