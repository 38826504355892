import React, { useEffect } from 'react';
import {
  FormHelperText,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useAuth } from '../../services/contexts';
import api from '../../Middleware/api/api';
import { useTranslation } from 'react-i18next';

function MultipleParkingLotEdit({
  data,
  isReadOnly,
  payload,
  setPayload,
  submit,
  setShowFeedback,
  setShowFeedbackError,
  showEmptyFieldError,
  checkParkingLotChange,
}) {
  const { t } = useTranslation();
  const { token } = useAuth();
  const cols = [
    {
      name: 'locationName',
      label: t('LBLLocation'),
    },
    {
      name: 'cpId',
      label: 'Station-ID',
    },
    {
      name: 'connectorId',
      label: t('connectorId'),
    },
    {
      name: 'parkingLot',
      label: t('LBLParkingLotNum'),
      type: 'text',
      textField: {
        label: t('LBLParkingLotNum'),
      },
    },
  ];
  useEffect(() => {
    setPayload(
      data.map((value) => ({
        connectorId: value.connectorId,
        cpId: value.cpId,
        locationId: value.locationId,
        parkingLotNumber: value.address.parkingLot,
        changed: false,
      }))
    );
  }, []);

  function handleChange(event) {
    setPayload((prevState) =>
      prevState.map((value, index) => {
        if (index == event.target.name.split('-')[1]) {
          return {
            ...value,
            parkingLotNumber: event.target.value,
            changed: event.target.value != data[index].parkingLotNumber,
          };
        }
        return value;
      })
    );
  }
  useEffect(async () => {
    if (submit > 0) {
      api.changeParkingLot(token, payload).then(
        (response) => {
          setShowFeedback(true);
        },
        (error) => {
          const message =
            error.response.data.error.errorCodeResponse === 'DATA_INCONSISTENCY'
              ? {
                  title: t('dupParkinglotTitle'),
                  detail:
                    t('dupParkinglotBody1') +
                    error.response.data.error.errorMessage +
                    t('dupParkinglotBody2'),
                }
              : {
                  title: t('wrongInput'),
                  detail:
                    error.response.data.error.errorMessage ||
                    t('LBLSomethingwentWrong'),
                };
          setShowFeedbackError(message);
        }
      );
    }
  }, [submit]);
  return (
    <>
      <TableContainer component={Paper} variant="outlined">
        <Table
          aria-label="simple table"
          sx={({ palette, typography }) => ({
            '& .MuiTableHead-root .MuiTableCell-root': {
              fontWeight: 600,
              fontSize: typography.subtitle2.fontSize,
            },

            '& .MuiTableRow-root .MuiTableCell-root': {
              width: 140,
              borderLeft: `1px solid ${palette.divider}`,
              borderRight: `1px solid ${palette.divider}`,
            },
          })}
        >
          <TableHead>
            <TableRow>
              {cols?.map((column) => (
                <TableCell key={column.name}>{column.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row, rowIndex) => (
              // eslint-disable-next-line react/jsx-key
              <TableRow>
                {cols?.map((col, index) => {
                  if (col.type === 'text') {
                    if (isReadOnly)
                      return (
                        <TableCell>
                          {payload[rowIndex]?.parkingLotNumber}
                        </TableCell>
                      );
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableCell>
                        <Input
                          variant="outlined"
                          id={`field-${index}`}
                          inputProps={{
                            'data-id': index,
                          }}
                          name={`field-${rowIndex}`}
                          value={payload[rowIndex]?.parkingLotNumber || ''}
                          onChange={handleChange}
                          error={
                            showEmptyFieldError &&
                            !payload[rowIndex]?.parkingLotNumber
                          }
                        />
                        <FormHelperText
                          error
                          sx={{
                            display:
                              showEmptyFieldError &&
                              !payload[rowIndex]?.parkingLotNumber
                                ? 'show'
                                : 'none',
                          }}
                        >
                          {t('parkingNumreq')}
                        </FormHelperText>
                        <FormHelperText
                          error
                          sx={{
                            display:
                              payload[rowIndex]?.parkingLotNumber?.length > 5
                                ? 'show'
                                : 'none',
                          }}
                        >
                          {t('maxparkingLot')}
                        </FormHelperText>

                        <FormHelperText
                          error
                          sx={{
                            display:
                              checkParkingLotChange &&
                              !payload[rowIndex]?.changed
                                ? 'show'
                                : 'none',
                          }}
                        >
                          {t('fildnchange')}
                        </FormHelperText>
                      </TableCell>
                    );
                  }
                  // eslint-disable-next-line react/jsx-key
                  return col.name == 'connectorId' ? (
                    <TableCell>{row[col.name]?.split('_').pop()}</TableCell>
                  ) : (
                    <TableCell>{row[col.name]}</TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default MultipleParkingLotEdit;
