import React from 'react';
import { Helmet } from 'react-helmet-async';
import Backdrop from '@mui/material/Backdrop';
import Loader from '../vendor/logoOli.svg';
import { Stack } from '@mui/system';
import { Button, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DetailsWrapper = ({
  title,
  backButtonConfig,
  children,
  helmet,
  loading,
  contract,
}) => (
  <>
    <Helmet title={helmet} />
    {loading && (
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <img src={Loader} alt="loader" className="loader" />
      </Backdrop>
    )}
    <Stack alignItems="flex-start" spacing={2} mb={4}>
      <Stack>
        <Typography variant="h3" gutterBottom display="inline">
          {title}
        </Typography>
        <Button
          size="medium"
          color="inherit"
          sx={{ color: 'grey.700' }}
          startIcon={<ArrowBackIcon fontSize="small" />}
          component={NavLink}
          id="backToOverview"
          to={backButtonConfig.url}
        >
          {backButtonConfig.label}
        </Button>
      </Stack>
      {contract}
    </Stack>
    {children}
  </>
);

export default DetailsWrapper;
