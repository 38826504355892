import 'dayjs/locale/de';
import PropTypes from 'prop-types';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import Stack from '@mui/material/Stack';
import MuiSelect from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import shadows from '@mui/material/styles/shadows';
import jwtDecode from 'jwt-decode';
import * as Yup from 'yup';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { Field, Form, Formik } from 'formik';
import { RadioGroup, Select } from 'formik-mui';
import { TextField } from '@mui/material';
import { useAuth } from '../../../../services/contexts/AuthContext';
import LightTooltip from './LightTooltip';
import api from '../../../../Middleware/api/api';
import { useTranslation } from 'react-i18next';
import { userTypeListWithTranlations } from '../../../../services/utils/translations-constants.js';
import { useTokenUtils } from '../../../../services/utils/tokenUtils';
let rows = [];
/**
 * @param {PropTypes.InferProps<UserCreationFormLocation.propTypes>} props
 */
const UserCreationFormLocation = ({
  setPayloadOne,
  confirmPayload,
  payload,
  submit,
  setSubmit,
  setCanNext,
  CreateUpdate,
}) => {
  let defaultAuthType = 'APP';
  let defaultRFIDType = payload?.rfidSerialId?.length > 0 ? 'SN' : 'TAG';
  let defaultUserType = 'REGULAR';
  let defaultDisabledLocationAndParkingLot = false;
  const { t } = useTranslation();
  const { token } = useAuth();
  const decodedToken = token ? jwtDecode(token) : null;
  const { isAdminRole } = useTokenUtils();
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [parkingInfo, setParkingInfo] = useState(null);
  const [cpoPaymentPreference, setCpoPaymentPreference] = useState(null);
  const [authType, setAuthType] = useState(defaultAuthType);
  const [rfidType, setRfidType] = useState(defaultRFIDType);
  const [tooltipOpen, setTooltipOpen] = useState('');
  const [minStartDate, setMinStartDate] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const userLocationFormRef = useRef();
  const [operators, setOperators] = useState([]);

  const determineDefaultDisabledLocationAndParkingLotInitialValue = (
    payload
  ) => {
    if (CreateUpdate === 'create') {
      if (!payload?.userType) {
        return defaultDisabledLocationAndParkingLot;
      } else {
        return payload?.userType === 'REGULAR' ? false : true;
      }
    }
    if (CreateUpdate === 'update') {
      return payload?.userType === 'REGULAR' ? false : true;
    }
  };

  const determineIsStripeSepaInitialValue = (payload) => {
    if (CreateUpdate === 'create') {
      return payload?.cpoPaymentPreference === 'STRIPE_SEPA';
    }
    if (CreateUpdate === 'update') {
      return payload?.contractPaymentDetails?.paymentMethod == 'STRIPE_SEPA';
    }
  };

  const [disabledLocationAndParkingLot, setDisabledLocationAndParkingLot] =
    useState(
      determineDefaultDisabledLocationAndParkingLotInitialValue(payload)
    );
  const [isStripeSepa, setIsStripeSepa] = useState(
    determineIsStripeSepaInitialValue(payload)
  );
  let selectedLocationvalues;

  const userTypeListDropDownForOperator = ['REGULAR', 'LOCAL_ADMIN'];
  const userTypeListDropDownForAdmin = [
    'REGULAR',
    'LOCAL_ADMIN',
    'GLOBAL_ADMIN',
  ];
  const [userTypeList, setUserTypeList] = useState(
    isAdminRole ? userTypeListDropDownForAdmin : userTypeListDropDownForOperator
  );
  const determineDefaultUserTypeInitialValue = (payload) => {
    if (CreateUpdate === 'create') {
      return payload?.userType || defaultUserType;
    }
    if (CreateUpdate == 'update') {
      return payload?.userType;
    }
  };
  const [selectedUserType, setSelectedUserType] = useState(
    determineDefaultUserTypeInitialValue(payload)
  );

  const determineCpoIdValueInitialValue = (payload, decodedToken) => {
    let val = '';
    if (CreateUpdate === 'create') {
      if (decodedToken?.customer_type === 'Operator')
        val = payload?.cpoId || decodedToken?.cpo_id[0];
      else val = payload?.cpoId || '';
    }
    if (CreateUpdate === 'update') {
      val = payload?.cpoId;
    }
    return val;
  };

  const [selectedCpoId, setSelectedCpoId] = useState(
    determineCpoIdValueInitialValue(payload, decodedToken)
  );

  const locationIdValue = (inputObj) => {
    if (inputObj != null) {
      let returnValue =
        inputObj?.locationId + ':' + inputObj?.locationName || '';
      return returnValue;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (CreateUpdate === 'update') {
      setAuthType(payload?.authType);
    }
    getParkingInfo();
    getOperators();
    let locationId = '';
    let locationName = '';
    let selectedParkingInfo = null;
    setSelectedOptions(selectedParkingInfo?.parkingLots);
    setSelectedLocation({
      locationId,
      locationName,
      ...selectedParkingInfo,
    });
    setIsStripeSepa(selectedParkingInfo?.cpoPaymentPreference == 'STRIPE_SEPA');
  }, []);

  const getParkingInfo = async () => {
    try {
      api.fetchLocationsForDropdowns(token).then(
        (response) => {
          if (response.data.data == null) {
            rows = [];
          } else {
            rows = response.data.data;
            setParkingInfo(response.data.data);
            let newValue = initialValues.locationId;
            selectedLocationvalues = rows?.find(({ locationIdName }) => {
              return locationIdName === newValue;
            });
            setSelectedOptions(selectedLocationvalues?.parkingLots);
            setCpoPaymentPreference(
              selectedLocationvalues?.cpoPaymentPreference
            );
            const [locationId, locationName] =
              // eslint-disable-next-line no-unsafe-optional-chaining
              initialValues.locationId?.split(':');

            setSelectedLocation({
              locationId,
              locationName,
              ...selectedLocationvalues,
            });
          }
        },
        () => {
          rows = [];
        }
      );
    } catch (exception) {
      rows = [];
    }
  };
  const getOperators = async () => {
    try {
      api.fetchOperators(token, 0, 10000).then(
        (response) => {
          if (response.data.data == null) {
            setOperators([]);
          } else {
            setOperators(response.data.data.response);
          }
        },
        () => {
          setOperators([]);
        }
      );
    } catch (exception) {
      setOperators([]);
    }
  };

  useEffect(() => {
    if (submit > 0) document.getElementById('subOne').click();
    setSubmit(0);
    let today = new Date();
    let yyyy = today.getFullYear();
    setMinStartDate(
      yyyy + today.toLocaleString('en-US', { month: '2-digit' }) + '01'
    );
    userLocationFormRef.current.setFieldError('userType', false);
  }, [submit, minStartDate]);

  const billingTypeInitialValue = (payload) => {
    if (
      payload?.userType === 'LOCAL_ADMIN' ||
      selectedUserType === 'LOCAL_ADMIN' ||
      payload?.userType === 'GLOBAL_ADMIN' ||
      selectedUserType === 'GLOBAL_ADMIN'
    ) {
      return 'NO_BILLING';
    } else if (payload?.billingType) {
      return payload?.billingType;
    }
    return 'WITH_BILLING';
  };
  const authTypeInitialValue = (payload) => {
    if (
      payload?.userType === 'LOCAL_ADMIN' ||
      selectedUserType === 'LOCAL_ADMIN' ||
      payload?.userType === 'GLOBAL_ADMIN' ||
      selectedUserType === 'GLOBAL_ADMIN'
    ) {
      return 'RFID';
    } else if (payload?.authType) {
      return payload?.authType;
    }
    return defaultAuthType;
  };

  const userTypeInitialValue = (payload) => {
    if (selectedUserType) {
      return selectedUserType;
    } else if (payload?.userType) {
      return payload?.userType;
    } else {
      return defaultUserType;
    }
  };
  const initialValues = {
    userType: userTypeInitialValue(payload),
    cpoId: payload?.cpoId || selectedCpoId,
    locationId: locationIdValue(payload) || '',
    locationName: payload?.locationName || '',
    billingType: billingTypeInitialValue(payload) || '',
    authType: authTypeInitialValue(payload),
    rfidType: payload?.rfidType || defaultRFIDType,
    parkingLotNumbers: payload?.parkingLotNumbers || [],
    cpoPaymentPreference: payload?.cpoPaymentPreference || '',
    rfidTags:
      payload?.rfidTags?.length > 1
        ? payload?.rfidTags.join()
        : payload?.rfidTags?.length > 0
          ? payload?.rfidTags[0]
          : '',
    rfidSTags:
      payload?.rfidSerialId?.length > 1
        ? payload?.rfidSerialId.join()
        : payload?.rfidSerialId?.length > 0
          ? payload?.rfidSerialId[0]
          : '',
  };
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        cpoId: Yup.string().required(t('LBLOpIdreq')).label(t('LBLOpId')),
        userType: Yup.string()
          .required(t('LBLUserTypeReq'))
          .label(t('LBLUserType')),
        locationId: disabledLocationAndParkingLot
          ? ''
          : Yup.string().required(t('locReq')).label(t('LBLLocation')),

        parkingLotNumbers: disabledLocationAndParkingLot
          ? null
          : Yup.array()
              .min(1, t('parkingnumreq'))
              .required(t('parkingnumreq'))
              .label(t('LBLParkingLotNum')),
        rfidTags:
          authType === defaultAuthType
            ? Yup.string().label('RFID-Tags')
            : rfidType == 'TAG'
              ? Yup.string().required(t('rfIdreq')).label('RFID-Tags')
              : Yup.string().label('RFID-Tags'),

        rfidSTags:
          authType === defaultAuthType
            ? Yup.string().label('RFID-SN')
            : rfidType != 'TAG'
              ? Yup.string().required(t('rfIdSreq')).label('RFID-SN')
              : Yup.string().label('RFID-SN'),
      }),
    [authType, rfidType, selectedUserType]
  );

  /**
   * @method
   * @param {Props['values']} values
   * @param {Props['handleChange']} handleChange
   * @returns {(event: React.ChangeEvent<HTMLInputElement>) => void}
   */

  const handleAuthTypeChange = (handleChange) => (event) => {
    setAuthType(event.target.value);
    handleChange(event);
  };
  const handleRFIDTypeChange = (handleChange) => (event) => {
    setRfidType(event.target.value);
    handleChange(event);
  };

  const handleUserTypeSelect =
    (setFieldValue, setFieldTouched, handleChange, setFieldError, values) =>
    (event) => {
      const newValue = event.target.value;
      setSelectedUserType(newValue);
      if (newValue === 'LOCAL_ADMIN' || newValue === 'GLOBAL_ADMIN') {
        setDisabledLocationAndParkingLot(true);
        setAuthType('RFID');
        setFieldValue('authType', 'RFID');
        setCpoPaymentPreference('NO_PAYMENT_METHOD');
      } else {
        setDisabledLocationAndParkingLot(false);
        setAuthType('APP');
        setFieldValue('authType', 'APP');
      }
    };

  const handleCpoIdChange =
    (setFieldValue, setFieldTouched, handleChange, setFieldError) =>
    (event) => {
      const newValue = event.target.value;
      setSelectedCpoId(newValue);
      setFieldValue('cpoId', newValue);
      userLocationFormRef.current.setFieldValue('cpoId', newValue);
      setIsStripeSepa(false);
    };
  const handleLocationSelect =
    (setFieldValue, setFieldTouched, handleChange, setFieldError) =>
    (event) => {
      const newValue = event.target.value;
      const selectedParkingInfo = parkingInfo?.find(({ locationIdName }) => {
        return locationIdName === newValue;
      });
      setSelectedOptions(selectedParkingInfo?.parkingLots);
      setCpoPaymentPreference(selectedParkingInfo.cpoPaymentPreference);
      const [locationId, locationName] =
        // eslint-disable-next-line no-unsafe-optional-chaining
        selectedParkingInfo?.locationIdName.split(':');

      setSelectedLocation({
        locationId,
        locationName,
        ...selectedParkingInfo,
      });

      setFieldValue('parkingLotNumbers', []);
      setIsStripeSepa(
        selectedParkingInfo?.cpoPaymentPreference == 'STRIPE_SEPA'
      );
      if (
        authType == 'RFID' &&
        selectedParkingInfo?.cpoPaymentPreference == 'STRIPE_SEPA'
      ) {
        setFieldValue('authType', defaultAuthType);
        setAuthType(defaultAuthType);
      }
      setFieldTouched('parkingLotNumbers', true, true);
      setFieldError('parkingLotNumbers', 'requiured');

      setFieldValue('userType', payload?.userType || selectedUserType);
      setFieldValue('cpoId', selectedParkingInfo?.cpoId);
      userLocationFormRef.current.setFieldValue(
        'cpoId',
        selectedParkingInfo?.cpoId
      );
    };

  const handleParkingLotSelect =
    (setFieldValue, setFieldError, setFieldTouched) => (event) => {
      const { value } = event.target;
      const selectedParkingLots =
        typeof value === 'string' ? value.split(',') : value;
      setFieldValue(
        'parkingLotNumbers',
        selectedParkingLots.includes('all')
          ? selectedLocation.parkingLots
          : selectedParkingLots
      );
      setFieldTouched('parkingLotNumbers', true, true);
      setFieldError('parkingLotNumbers', 'requiured');
    };

  const openTooltip = (name) => () => setTooltipOpen(name);
  const closeTooltip = () => setTooltipOpen('');

  const customHandleBlur = (handleBlur) => (event) => {
    closeTooltip();
    handleBlur(event);
  };
  function splitTheString(CommaSepStr) {
    var ResultArray = null;

    // Check if the string is null or so.
    if (CommaSepStr != null) {
      var SplitChars = ',';
      // Check if the string has comma of not will go to else
      if (CommaSepStr.indexOf(SplitChars) >= 0) {
        ResultArray = CommaSepStr.split(SplitChars);
      } else {
        // The string has only one value, and we can also check
        // the length of the string or time and cross-check too.
        ResultArray = [CommaSepStr];
      }
    }
    return ResultArray;
  }
  /** @type {import("formik").FormikConfig<typeof initialValues>['onSubmit']} */
  const handleSubmit = (values, actions) => {
    const [locationId, locationName] = selectedLocation?.locationIdName
      ? selectedLocation.locationIdName.split(':')
      : [null, null];

    const payloadVal = {
      userType: values.userType,
      locationId: locationId,
      cpoPaymentPreference:
        CreateUpdate != 'update'
          ? cpoPaymentPreference
          : payload?.billingType == 'WITH_BILLING'
            ? payload?.contractPaymentDetails?.paymentMethod
            : null,
      locationName: locationName,
      parkingLotNumbers: values.parkingLotNumbers,
      cpoId: values.cpoId,
      authType: values.authType, // can be "APP" or "RFID"
      rfidType: values.rfidType, // can be "APP" or "RFID"
      rfidTags:
        values.authType != defaultAuthType && rfidType == 'TAG'
          ? splitTheString(values?.rfidTags)
          : null,
      rfidSTags:
        values.authType != defaultAuthType && rfidType != 'TAG'
          ? splitTheString(values?.rfidSTags)
          : null,
    };
    setPayloadOne(payloadVal);
    confirmPayload();
    actions.setSubmitting(false);
    actions.setTouched({}, true);
    actions.setErrors({});
    actions.setFieldError({});
    actions.setFieldTouched({}, true, true);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnMount={true}
      onSubmit={handleSubmit}
      innerRef={userLocationFormRef}
    >
      {({
        errors,
        touched,
        values,
        setFieldValue,
        setFieldError,
        setFieldTouched,
        handleChange,
        isSubmitting,
        handleBlur,
        isValid,
      }) => (
        <Form noValidate>
          <>
            {setCanNext(touched && isValid)}

            <Stack mb={2} direction="row" spacing={2}>
              <Box width="20%">
                <LightTooltip
                  open={tooltipOpen === 'userType'}
                  onOpen={openTooltip('userType')}
                  onClose={closeTooltip}
                  placement="bottom-start"
                  title={
                    <>
                      <Typography>
                        {t('LBLLocalAdminUserTypeDescription')}
                      </Typography>
                      <Typography>
                        <>
                          <br></br>
                        </>
                      </Typography>
                      <Typography>
                        {t('LBLRegularUserTypeDescription')}
                      </Typography>
                    </>
                  }
                >
                  <InfoIcon
                    color="action"
                    fontSize="small"
                    sx={{ position: 'absolute', ml: -6, mt: 2 }}
                  />
                </LightTooltip>

                <Field
                  disabled={CreateUpdate === 'update'}
                  component={Select}
                  formControl={{
                    required: true,
                    fullWidth: true,
                    size: 'small',
                    margin: 'none',
                  }}
                  onChange={handleUserTypeSelect(
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    setFieldError,
                    values
                  )}
                  id="userTypeid"
                  name="userType"
                  label={t('LBLUserType')}
                  displayEmpty={true}
                  defaultValue={selectedUserType}
                  value={selectedUserType}
                  MenuProps={{
                    PaperProps: { sx: { boxShadow: shadows[3] } },
                  }}
                >
                  {userTypeList?.map((usertype) => (
                    <MenuItem key={usertype} value={usertype}>
                      <ListItemText>
                        {userTypeListWithTranlations(t)[usertype] || userType}
                      </ListItemText>
                    </MenuItem>
                  ))}
                </Field>
              </Box>

              <Box width="20%">
                {values.userType === 'REGULAR' ||
                selectedUserType === 'REGULAR' ||
                decodedToken?.customer_type === 'Operator' ||
                CreateUpdate === 'update' ? null : (
                  <Field
                    disabled={
                      values.userType === 'REGULAR' ||
                      selectedUserType === 'REGULAR' ||
                      decodedToken?.customer_type === 'Operator' ||
                      CreateUpdate === 'update'
                    }
                    component={Select}
                    formControl={{
                      fullWidth: true,
                      size: 'small',
                      margin: 'none',
                    }}
                    onChange={handleCpoIdChange(
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      setFieldError
                    )}
                    id="operatorId_Id"
                    name="cpoId"
                    label={t('LBLOpId')}
                    displayEmpty={true}
                    defaultValue={selectedCpoId}
                    value={selectedCpoId}
                    MenuProps={{
                      PaperProps: { sx: { boxShadow: shadows[3] } },
                    }}
                  >
                    {operators.map((operator, index) => (
                      <MenuItem key={index} value={operator.cpoId}>
                        {operator.cpoId + ': ' + operator.cpoName}
                      </MenuItem>
                    ))}
                  </Field>
                )}
              </Box>
            </Stack>
            {selectedUserType === 'REGULAR' ? (
              <Stack mb={5} direction="row" spacing={8}>
                <Box width="100%">
                  <Field
                    disabled={
                      disabledLocationAndParkingLot ||
                      payload?.contractStatus === 'ACTIVE'
                    }
                    required
                    component={Select}
                    formControl={{
                      required: true,
                      fullWidth: true,
                      size: 'small',
                      margin: 'none',
                    }}
                    onChange={handleLocationSelect(
                      setFieldValue,
                      setFieldTouched,
                      handleChange,
                      setFieldError
                    )}
                    displayEmpty={true}
                    defaultValue={''}
                    id="standort"
                    name="locationId"
                    label={t('LBLLocation')}
                    MenuProps={{
                      PaperProps: { sx: { boxShadow: shadows[3] } },
                    }}
                  >
                    {parkingInfo?.map(({ locationIdName }) => (
                      <MenuItem key={locationIdName} value={locationIdName}>
                        {locationIdName}
                      </MenuItem>
                    ))}
                  </Field>
                </Box>
                <FormControl
                  fullWidth
                  size="small"
                  margin="none"
                  error={Boolean(
                    (!disabledLocationAndParkingLot &&
                      selectedLocation?.parkingLots?.length === 0) ||
                      (!disabledLocationAndParkingLot &&
                        values?.parkingLotNumbers?.length === 0 &&
                        touched?.parkingLotNumbers)
                  )}
                >
                  <InputLabel id="parkplatznummer-label">
                    {t('LBLParkingLotNum')}
                  </InputLabel>
                  <MuiSelect
                    disabled={disabledLocationAndParkingLot}
                    multiple
                    fullWidth
                    required={
                      !disabledLocationAndParkingLot &&
                      values?.parkingLotNumbers?.length === 0 &&
                      touched.parkingLotNumbers
                    }
                    displayEmpty={false}
                    value={values?.parkingLotNumbers}
                    renderValue={(selected) => selected.join(', ')}
                    onChange={handleParkingLotSelect(
                      setFieldValue,
                      setFieldError,
                      setFieldTouched
                    )}
                    onBlur={(event) => {
                      setFieldTouched('parkingLotNumbers', true, true);
                    }}
                    id="parkplatznummer"
                    labelId="parkplatznummer-label"
                    name="parkingLotNumbers"
                    label={t('LBLParkingLotNum')}
                    helpertext={
                      !disabledLocationAndParkingLot &&
                      selectedOptions?.parkingLots?.length === 0
                        ? t('noparkingnumas')
                        : errors.parkingLot
                    }
                    MenuProps={{
                      PaperProps: { sx: { boxShadow: shadows[3] } },
                    }}
                  >
                    {selectedOptions?.map((parkingLot) => (
                      <MenuItem key={parkingLot} value={parkingLot}>
                        <Checkbox
                          size="small"
                          checked={Boolean(
                            values.parkingLotNumbers.includes(parkingLot)
                          )}
                        />
                        <ListItemText>{parkingLot}</ListItemText>
                      </MenuItem>
                    ))}
                  </MuiSelect>
                  <FormHelperText
                    error={Boolean(
                      !disabledLocationAndParkingLot &&
                        selectedLocation?.parkingLots?.length === 0
                    )}
                    sx={{
                      display:
                        !disabledLocationAndParkingLot &&
                        selectedLocation?.parkingLots?.length === 0
                          ? 'show'
                          : 'none',
                    }}
                  >
                    {t('noparkingnumas')}
                  </FormHelperText>
                  <FormHelperText
                    error={Boolean(
                      !disabledLocationAndParkingLot &&
                        values?.parkingLotNumbers?.length === 0 &&
                        touched?.parkingLotNumbers
                    )}
                    sx={{
                      display:
                        !disabledLocationAndParkingLot &&
                        values?.parkingLotNumbers?.length === 0 &&
                        touched?.parkingLotNumbers
                          ? 'show'
                          : 'none',
                    }}
                  >
                    {t('parkingnumreq')}
                  </FormHelperText>
                </FormControl>
              </Stack>
            ) : null}
            <Stack mb={5} direction="row" spacing={8}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                <Field
                  margin="none"
                  sx={{ width: '100%' }}
                  component={RadioGroup}
                  aria-labelledby="autorisierung-uber-label"
                  name="authType"
                  onChange={handleAuthTypeChange(handleChange)}
                >
                  <Typography
                    fontWeight={700}
                    variant="subtitle1"
                    id="autorisierung-uber-label"
                  >
                    {t('authuser')}
                  </Typography>
                  <FormControlLabel
                    value="APP"
                    sx={{ width: 'fit-content' }}
                    control={<Radio />}
                    label="OLI Move App"
                    disabled={isSubmitting || disabledLocationAndParkingLot}
                  />
                  <FormControlLabel
                    value="RFID"
                    sx={{ width: 'fit-content' }}
                    control={<Radio />}
                    label="RFID"
                    disabled={isSubmitting || isStripeSepa}
                  />
                  <FormControlLabel
                    value="APP_AND_RFID"
                    sx={{ width: 'fit-content' }}
                    control={<Radio />}
                    label="OLI Move App und RFID"
                    disabled={isSubmitting || disabledLocationAndParkingLot}
                  />
                </Field>
              </Stack>

              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                width="100%"
              >
                {values.authType != 'APP' ? (
                  <Field
                    margin="none"
                    sx={{ width: '100%' }}
                    component={RadioGroup}
                    aria-labelledby="autorisierung-uber-label"
                    name="rfidType"
                    onChange={handleRFIDTypeChange(handleChange)}
                  >
                    <Typography
                      fontWeight={700}
                      variant="subtitle1"
                      id="autorisierung-uber-label"
                    >
                      {t('tagdetails')}
                    </Typography>
                    <FormControlLabel
                      value="TAG"
                      sx={{ width: 'fit-content' }}
                      control={<Radio disabled={isSubmitting} />}
                      label="Tag"
                      disabled={
                        isSubmitting || values.authType == defaultAuthType
                      }
                    />
                    <FormControlLabel
                      value="SN"
                      sx={{ width: 'fit-content' }}
                      control={<Radio disabled={isSubmitting} />}
                      label={t('rfIdSN')}
                      disabled={
                        isSubmitting || values.authType == defaultAuthType
                      }
                    />
                  </Field>
                ) : null}
              </Stack>
              <Box position="relative" width="100%">
                <LightTooltip
                  open={tooltipOpen === 'rfid-tags'}
                  onOpen={openTooltip('rfid-tags')}
                  onClose={closeTooltip}
                  placement="bottom-start"
                  title={
                    <>
                      <Typography>{t('commasep')}</Typography>
                      <Typography>{t('example')}:</Typography>
                      <Typography>Tag-1, Tag-2, Tag-3</Typography>
                    </>
                  }
                >
                  <InfoIcon
                    color="action"
                    fontSize="small"
                    sx={{ position: 'absolute', ml: -6, mt: 2 }}
                  />
                </LightTooltip>

                <TextField
                  margin="none"
                  id="rfid-tags"
                  label="RFID-Tags"
                  name="rfidTags"
                  variant="outlined"
                  required={
                    values.authType != defaultAuthType &&
                    values.rfidType === 'TAG'
                  }
                  disabled={
                    values.authType == defaultAuthType ||
                    values.rfidType != 'TAG'
                  }
                  value={values.rfidTags}
                  sx={{
                    '& .Mui-disabled': {
                      bgcolor: 'grey.400',
                    },
                  }}
                  onChange={handleChange}
                  onFocus={openTooltip('rfid-tags')}
                  onBlur={customHandleBlur(handleBlur)}
                  error={touched.rfidTags && Boolean(errors.rfidTags)}
                  helperText={touched.rfidTags && errors.rfidTags}
                  fullWidth
                  multiline
                  my={2}
                />
              </Box>
              <Box position="relative" width="100%">
                <LightTooltip
                  open={tooltipOpen === 'rfidS-tags'}
                  onOpen={openTooltip('rfidS-tags')}
                  onClose={closeTooltip}
                  placement="bottom-start"
                  title={
                    <>
                      <Typography>{t('commasepS')}</Typography>
                      <Typography>{t('example')}:</Typography>

                      <Typography>SN-1, SN-2, SN-3</Typography>
                    </>
                  }
                >
                  <InfoIcon
                    color="action"
                    fontSize="small"
                    sx={{ position: 'absolute', ml: -6, mt: 2 }}
                  />
                </LightTooltip>
                <TextField
                  margin="none"
                  id="rfid-SN"
                  name="rfidSTags"
                  label="RFID-SN"
                  variant="outlined"
                  disabled={
                    values.authType === defaultAuthType ||
                    values.rfidType === 'TAG'
                  }
                  required={
                    values.authType != defaultAuthType &&
                    values.rfidType != 'TAG'
                  }
                  value={values.rfidSTags}
                  sx={{
                    '& .Mui-disabled': {
                      bgcolor: 'grey.400',
                    },
                  }}
                  onChange={handleChange}
                  onFocus={openTooltip('rfidS-tags')}
                  onBlur={customHandleBlur(handleBlur)}
                  error={touched.rfidSTags && Boolean(errors.rfidSTags)}
                  helperText={touched.rfidSTags && errors.rfidSTags}
                  fullWidth
                  multiline
                  my={2}
                />
              </Box>
            </Stack>
            <Button sx={{ display: 'none' }} id="subOne" type="submit"></Button>
          </>
        </Form>
      )}
    </Formik>
  );
};

UserCreationFormLocation.propTypes = {
  payload: PropTypes.objectOf(PropTypes.any),
  setPayloadOne: PropTypes.func.isRequired,
  confirmPayload: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default UserCreationFormLocation;
