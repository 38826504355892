import React, { useEffect, useState, Suspense } from 'react';
import styled from 'styled-components/macro';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import {
  Divider as MuiDivider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Stack,
  ThemeProvider,
  Typography,
  useTheme,
  TableCell,
} from '@mui/material';
import Box from '@mui/system/Box';
import spacing from '@mui/system/spacing';
import DataTable, {
  statusMappingBackendToFrontend,
} from '../../components/DataTable';
import { useAuth } from '../../services/contexts/AuthContext';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import shadows from '@mui/material/styles/shadows';
import StepperPopup from '../../components/StepperPopup';
import MultipleParkingLotEdit from '../../components/multipleEditDialog/MultipleParkingLotEdit';
import { ArrowForward } from '@mui/icons-material';
import MultipleStatusEdit from '../../components/multipleEditDialog/MultipleStatusEdit';
import Feedback from '../../components/DataTable/Feedback';
import { createTheme } from '@mui/material/styles';
import CreateStation from './components/StationForm';
import { useTranslation } from 'react-i18next';
import api from '../../Middleware/api/api';
import { PAGE_SIZE } from '../../services/utils/units-constants';
const Divider = styled(MuiDivider)(spacing);

const dataTableTheme = () =>
  createTheme({
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="5"]': {
              '& > *': {
                justifyContent: 'center',
                textAlign: 'center',
              },
            },
            '&[data-colindex="1"]': {
              '& > *': {
                justifyContent: 'left',
                textAlign: 'left',
                paddingLeft: 12,
              },
            },
            '&[data-colindex="6"], &[data-colindex="7"]': {
              '& > *': {
                justifyContent: 'right',
                textAlign: 'right',
                paddingLeft: 12,
              },
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            '&[data-colindex="4"]': {
              '& > *': {
                justifyContent: 'center',
                textAlign: 'center',
                paddingRight: 10,
              },
            },
          },
        },
      },
    },
  });

export const mapStatus = (value, parkingLot, t) => {
  let renderStatus = '';
  if (value === 'connected_unavailable') {
    renderStatus = parkingLot
      ? statusMappingBackendToFrontend.connected_unavailable.withParkingLot
      : statusMappingBackendToFrontend.connected_unavailable.noParkingLot;
  } else if (value === 'idle') {
    renderStatus = t('LBLActive');
  } else renderStatus = value;
  return renderStatus;
};

function Stations() {
  const { t, i18n } = useTranslation();
  const { status } = useTheme();
  const { token } = useAuth();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [selected, setSelected] = useState([]);
  const [payload, setPayload] = useState([]);
  const [submit, setSubmit] = useState(0);
  const [isStatusEditModalOpen, setIsStatusEditModalOpen] = useState(false);
  const [statusDialogData, setStatusDialogData] = useState([]);
  const [parkingDialogData, setParkingDialogData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showFeedbackParking, setShowFeedbackParking] = useState(false);
  const [showFeedbackStatus, setShowFeedbackStatus] = useState(false);
  const [checkStatusChange, setCheckStatusChange] = useState(false);
  const [showErrorEmptyParkingEdit, setShowErrorEmptyParkingEdit] =
    useState(false);
  const [showNoRowSelected, setShowNoRowSelected] = useState(false);
  const [errorTitle, setErrorTitle] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE[0]);

  const [stationsData, setStationsData] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!isStatusEditModalOpen) setCheckStatusChange(false);
  }, [isStatusEditModalOpen]);

  const columns = [
    {
      name: 'connectorId',
      options: {
        display: false,
        filter: false,
        customBodyRender: (value) => {
          return value || <>-</>;
        },
      },
    },
    {
      name: 'cpId',
      label: 'Station-ID',
      options: {
        align: 'left',
        filter: false,
        customHeadRender: () => (
          <TableCell
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            Station-ID
          </TableCell>
        ),
      },
    },
    {
      name: 'connectorId',
      label: t('connectorId'),
      options: {
        align: 'left',
        filter: false,
        customBodyRender: (value) => {
          return (
            <>
              <Typography align="center">{value?.split('_')?.pop()}</Typography>
            </>
          );
        },
        customHeadRender: () => (
          <TableCell
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('connectorId')}
          </TableCell>
        ),
      },
    },
    {
      name: 'locationName',
      label: t('LBLLocation'),
      options: {
        align: 'left',
        filter: true,
        filterOptions: {
          fullWidth: true,
        },
        customHeadRender: () => (
          <TableCell
            align="left"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLLocation')}
          </TableCell>
        ),
      },
    },
    {
      name: 'address',
      label: t('LBLParkingLot'),
      options: {
        filter: false,
        customBodyRender: (address) => {
          return (
            <>
              <Typography align="left">{address.parkingLot}</Typography>
            </>
          );
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLParkingLot')}
          </TableCell>
        ),
      },
    },
    {
      name: 'wallboxModel',
      label: t('LBLModelOfCharginStation'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          return <Typography align="left">{value}</Typography>;
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              backgroundColor: 'white',
            }}
          >
            {t('LBLModelOfCharginStation')}
          </TableCell>
        ),
      },
    },
    {
      name: 'connectorStatusMapping',
      label: t('LBLCHarginStationStatus'),
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (!value) return null;
          return (
            <Typography align="center" color={value.colourCode}>
              {t(value.enumName)}
            </Typography>
          );
        },
        customHeadRender: () => (
          <TableCell
            align="center"
            variant="head"
            sx={{ position: 'sticky', top: 0, backgroundColor: 'white' }}
          >
            {t('LBLCHarginStationStatus')}
          </TableCell>
        ),
      },
    },

    {
      name: 'edit',
      label: ' ',
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value) => {
          return (
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <IconButton onClick={handleClickIconMenu(value)}>
                  <EditIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorIconButton}
                  key={value}
                  open={openElem === value}
                  onClose={handleCloseIconMenu}
                  MenuListProps={{
                    'aria-labelledby': 'edit-selected',
                  }}
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <MenuItem
                    key={value}
                    onClick={() => {
                      setParkingDialogData(
                        rows
                          .filter((row) => value === row.connectorId)
                          .map((row) => ({
                            ...row,
                            parkingLot: row.address.parkingLot,
                          }))
                      );
                      handleCloseIconMenu();
                    }}
                  >
                    <Typography align="center" sx={{ width: '100%' }}>
                      {t('LBLParkplatzhinterlegen')}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setStatusDialogData(
                        rows
                          .filter((row) => value === row.connectorId)
                          .map((row) => ({
                            ...row,
                            parkingLot: row.address.parkingLot,
                          }))
                      );
                      handleCloseIconMenu();
                    }}
                  >
                    {t('ChangechargingStatus')}
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={8}>
                <Button
                  component={NavLink}
                  to={`/stationen/${value}/`}
                  variant="text"
                >
                  {t('LBLDetails')}
                </Button>
              </Grid>
            </Grid>
          );
        },
      },
    },
  ];
  useEffect(() => {
    setSelected(selectedRows.map((index) => rows[index].connectorId));
  }, [selectedRows]);
  useEffect(() => {
    if (parkingDialogData.length > 0) {
      if (
        parkingDialogData.filter((row) => row.connectorStatus === 'INACTIVE')
          .length > 0
      ) {
        setErrorTitle(t('LBLWrongSelection'));
        setError(
          <>
            {t('cswithstatus')}{' '}
            <span style={{ color: status.inaktiv }}>{t('LBLInactive')}</span>{' '}
            {t('cantedit')}.
          </>
        );
        setParkingDialogData([]);
      } else setIsEditModalOpen(true);
    }
  }, [parkingDialogData]);
  useEffect(() => {
    if (statusDialogData.length > 0) {
      if (
        statusDialogData.filter(
          (row) =>
            row.connectorStatus === 'IDLE' ||
            (row.connectorStatus === 'CONNECTED_UNAVAILABLE' &&
              !!row.parkingLot)
        ).length === statusDialogData.length
      ) {
        setIsStatusEditModalOpen(true);
      } else if (
        statusDialogData.filter(
          (row) =>
            !(
              row.connectorStatus === 'IDLE' ||
              (row.connectorStatus === 'CONNECTED_UNAVAILABLE' &&
                !!row.parkingLot) ||
              row.connectorStatus === 'OCCUPIED_UNCHARGED' ||
              row.connectorStatus === 'OCCUPIED_CHARGING'
            )
        ).length > 0
      ) {
        setErrorTitle(t('notpossible'));
        setError(
          <>
            {t('csstatus')}{' '}
            <span style={{ color: status.standby }}>Standby</span>{' '}
            {i18n.language === 'en' ? 'or ' : 'oder '}
            <span style={{ color: status.aktiv }}>{t('LBLActive')}</span>{' '}
            {t('tobeedited')}
          </>
        );
        setStatusDialogData([]);
      } else if (
        statusDialogData.filter(
          (row) =>
            row.connectorStatus === 'OCCUPIED_UNCHARGED' ||
            row.connectorStatus === 'OCCUPIED_CHARGING'
        ).length > 0
      ) {
        setErrorTitle('Ladestation im Betrieb');
        setError(
          <>
            {t('LBLChastationInuse')}: <br />{' '}
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <ul>
                {statusDialogData
                  .filter(
                    (row) =>
                      row.connectorStatus === 'OCCUPIED_UNCHARGED' ||
                      row.connectorStatus === 'OCCUPIED_CHARGING'
                  )
                  .map((item) => (
                    <li key={item.cpId}>{item.cpId}</li>
                  ))}
              </ul>
            </Box>
            {t('csnotcahn')}
          </>
        );
        setStatusDialogData([]);
      }
    }
  }, [statusDialogData]);
  const closeErrorFeedback = () => setError('');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const sendData = () => {
    setSuccessfullPrakingLotUpdated((prev) => prev + 1);
    fetchStationsList(pageSize, currentPage);
  };
  const handleClose = () => {
    setAnchorEl(null);
    fetchStationsList(pageSize, currentPage);
  };
  const [openElem, setOpenElem] = React.useState(null);
  const [anchorIconButton, setAnchorIconButton] = useState(null);
  const [showDuplicateModel, setShowDuplicateModel] = useState(false);
  const [showDuplicateModelData, setShowDuplicateModelData] = useState('');
  const [showDuplicateModelTitle, setShowDuplicateModelTitle] = useState('');
  const handleClickIconMenu = (elem) => (event) => {
    setAnchorIconButton(event.currentTarget);
    setOpenElem(elem);
  };
  const handleCloseIconMenu = () => {
    setOpenElem(null);
    setAnchorIconButton(null);
  };
  const triggerMultipleStatusEdit = () => {
    setAnchorEl(null);
    if (selected.length > 0) {
      setStatusDialogData(
        rows
          .filter((row) => selected.includes(row.connectorId))
          .map((row) => ({ ...row, parkingLot: row.address.parkingLot }))
      );
    } else {
      setShowNoRowSelected(true);
    }
  };
  const triggerMultipleParkingEdit = () => {
    setAnchorEl(null);
    if (selected.length > 0) {
      setParkingDialogData(
        rows
          .filter((row) => selected.includes(row.connectorId))
          .map((row) => ({ ...row, parkingLot: row.address.parkingLot }))
      );
    } else {
      setShowNoRowSelected(true);
    }
  };
  const cols = [
    {
      name: 'locationName',
      label: t('LBLLocation'),
    },
    {
      name: 'cpId',
      label: 'Station-ID',
    },
    {
      name: 'parkingLot',
      label: t('LBLParkingLotNum'),
    },
    {
      name: 'connectorStatus',
      label: t('LBLCHarginStationStatus'),
      type: 'select',
      selectConfig: {
        label: t('LBLCHarginStationStatus'),
        values: [
          {
            value: 'IDLE',
            label:
              i18n.language === 'de'
                ? statusMappingBackendToFrontend['idle']
                : 'Active',
          },
          {
            value: 'CONNECTED_UNAVAILABLE',
            label: mapStatus('connected_unavailable', ' ', t),
          },
        ],
      },
    },
  ];
  const [successfullPrakingLotUpdated, setSuccessfullPrakingLotUpdated] =
    useState(0);

  useEffect(async () => {
    fetchStationsList(pageSize, currentPage);
  }, [currentPage, pageSize]);

  const fetchStationsList = (seletedPageSize, currentPageVal) => {
    try {
      setStationsData([]);
      api.fetchStationsList(token, currentPageVal, seletedPageSize).then(
        (response) => {
          if (response.data.data == null) {
            setStationsData([]);
          } else {
            setTotalRecords(response?.data?.data?.totalNumberOfRecords);
            setStationsData(response.data.data.response);
          }
        },
        () => {
          setStationsData([]);
        }
      );
    } catch (exception) {
      setStationsData([]);
    }
  };

  useEffect(() => {
    if (stationsData) {
      let itemListinitial = [];
      stationsData.forEach((elem) => {
        itemListinitial.push(elem);
      });
      setRows(itemListinitial);
    }
  }, [successfullPrakingLotUpdated, showFeedbackStatus, stationsData]);

  const handleChangeError = (input) => {
    setShowDuplicateModelData(input.detail);
    setShowDuplicateModelTitle(input.title);
    setShowDuplicateModel(true);
  };
  const closeDuplicateError = () => {
    setShowDuplicateModel(false);
    setSubmit(0);
    fetchStationsList(pageSize, currentPage);
  };
  return (
    <React.Fragment>
      <Helmet title="Invoices" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {t('LBLCharginStations')}
          </Typography>
        </Grid>
        <Grid item>
          <CreateStation sendData={sendData} />
        </Grid>
      </Grid>

      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box width="100%">
            <Stack alignItems="flex-end" spacing={8} position="relative">
              <Button
                variant="contained"
                onClick={handleClick}
                id="edit-selected"
                sx={{
                  // minWidth: 154,
                  position: 'absolute',
                  boxShadow: shadows[3],
                  top: ({ spacing }) => spacing(12),
                  left: ({ spacing }) => spacing(3),
                  zIndex: ({ zIndex }) => zIndex.appBar,
                }}
              >
                {t('LBLEditMulti')}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'edit-selected',
                }}
                sx={{
                  textAlign: 'center',
                }}
              >
                <MenuItem onClick={triggerMultipleParkingEdit}>
                  {' '}
                  <Typography align="center" sx={{ width: '100%' }}>
                    {t('LBLParkplatzhinterlegen')}
                  </Typography>{' '}
                </MenuItem>
                <MenuItem onClick={triggerMultipleStatusEdit}>
                  {t('ChangechargingStatus')}
                </MenuItem>
              </Menu>
              <Paper
                variant="outlined"
                sx={{
                  width: '100%',
                  '& .MuiTypography-h6': {
                    opacity: 0,
                  },
                }}
              >
                <ThemeProvider
                  theme={(appTheme) => ({
                    ...appTheme,
                    components: {
                      ...appTheme.components,
                      ...dataTableTheme(appTheme).components,
                    },
                  })}
                >
                  <DataTable
                    title={t('LBLCharginStations')}
                    data={
                      rows.map((row) => ({
                        ...row,
                        edit: row.connectorId,
                      })) || []
                    }
                    columns={columns}
                    options={{
                      textLabels: {
                        body: {
                          noMatch: !rows
                            ? t('getcharginstation')
                            : t('nocharginstation'),
                        },
                      },
                      onRowSelectionChange: (_props, allRowsSelected) => {
                        setSelectedRows(
                          allRowsSelected.map(({ index }) => index)
                        );
                      },
                      rowsSelected: selectedRows,
                      filter: false,
                      selectableRows: true,
                      currentPage: currentPage,
                      totalRecords: totalRecords,
                    }}
                    getData={(seletedPageSize, currentPageVal) => {
                      setCurrentPage(currentPageVal);
                      setPageSize(seletedPageSize);
                    }}
                  />
                </ThemeProvider>
              </Paper>
            </Stack>
            <Suspense fallback={<div />}>
              <StepperPopup
                isOpen={Boolean(isEditModalOpen)}
                setIsOpen={setIsEditModalOpen}
                title={t('LBLParkplatzhinterlegen')}
                steps={[
                  {
                    label: t('EnterINfo'),
                    component: (
                      <MultipleParkingLotEdit
                        data={parkingDialogData}
                        payload={payload}
                        setPayload={setPayload}
                        isReadOnly={false}
                        showEmptyFieldError={showErrorEmptyParkingEdit}
                        setShowFeedbackError={handleChangeError}
                      />
                    ),
                    nextAction: {
                      label: t('LBLFurther'),
                      icon: <ArrowForward />,
                      action: () => {
                        setShowErrorEmptyParkingEdit(true);
                      },
                      canNext:
                        payload.filter(
                          (row) =>
                            !row.parkingLotNumber ||
                            row.parkingLotNumber?.length > 5
                        ).length <= 0,
                    },
                  },
                  {
                    label: t('confirmInfo'),
                    component: (
                      <MultipleParkingLotEdit
                        data={parkingDialogData}
                        payload={payload}
                        setPayload={setPayload}
                        isReadOnly={true}
                        submit={submit}
                        setShowFeedback={setShowFeedbackParking}
                        setShowFeedbackError={handleChangeError}
                      />
                    ),
                    nextAction: {
                      label: t('LBLSave'),
                      icon: <></>,
                      action: () => {
                        setSubmit((prevState) => prevState + 1);
                        setIsEditModalOpen(false);
                        setShowErrorEmptyParkingEdit(false);
                      },
                      canNext: false,
                    },
                  },
                ]}
              />
              <StepperPopup
                isOpen={Boolean(isStatusEditModalOpen)}
                setIsOpen={setIsStatusEditModalOpen}
                title={t('ChangechargingStatus')}
                steps={[
                  {
                    label: t('LBLChangeEnter'),
                    component: (
                      <MultipleStatusEdit
                        data={statusDialogData}
                        cols={cols}
                        payload={payload}
                        setPayload={setPayload}
                        isReadOnly={false}
                        checkStatusChange={checkStatusChange}
                      />
                    ),
                    nextAction: {
                      label: t('LBLFurther'),
                      icon: <ArrowForward />,
                      action: () => {
                        setCheckStatusChange(true);
                      },
                      canNext:
                        !payload.filter((value) => value?.changed === false)
                          .length > 0,
                    },
                  },
                  {
                    label: t('LBLConfirmChanges'),
                    component: (
                      <MultipleStatusEdit
                        data={statusDialogData}
                        cols={cols}
                        payload={payload}
                        setPayload={setPayload}
                        submit={submit}
                        isReadOnly={true}
                        setShowFeedback={setShowFeedbackStatus}
                      />
                    ),
                    nextAction: {
                      label: t('LBLSave'),
                      icon: <></>,
                      action: () => {
                        setSubmit((prev) => prev + 1);
                        setIsStatusEditModalOpen(false);
                      },
                      canNext: false,
                    },
                  },
                ]}
              />
              <Feedback
                open={!!error}
                title={errorTitle}
                message={
                  <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
                    {error}
                  </Typography>
                }
                severity="warning"
                handleClose={closeErrorFeedback}
              />
              <Feedback
                open={showFeedbackParking}
                handleClose={() => {
                  setSuccessfullPrakingLotUpdated((prev) => prev + 1);
                  setShowFeedbackParking(false);
                  setSubmit(0);
                  fetchStationsList(pageSize, currentPage);
                }}
                message={t('ParkinglotUpdated')}
                title={t('lotNumberUpdated')}
              />
              <Feedback
                open={showNoRowSelected}
                handleClose={() => {
                  setShowNoRowSelected(false);
                }}
                severity="warning"
                message={
                  <Typography
                    variant="subtitle1"
                    fontWeight={400}
                    sx={{ width: 400, mx: 10 }}
                  >
                    {t('selectOnechstation')}
                  </Typography>
                }
              />
              <Feedback
                open={showDuplicateModel}
                title={showDuplicateModelTitle}
                message={
                  <Typography variant="subtitle1" sx={{ width: 400, mx: 10 }}>
                    {showDuplicateModelData}
                  </Typography>
                }
                severity="warning"
                handleClose={closeDuplicateError}
              />
              <Feedback
                open={showFeedbackStatus}
                handleClose={() => {
                  setShowFeedbackStatus(false);
                  setSubmit(0);
                  fetchStationsList(pageSize, currentPage);
                }}
                message={t('ParaCHarginst')}
                title={t('statussubmitted')}
              />
            </Suspense>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Stations;
