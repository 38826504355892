import React, { lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Container, Link as MuiLink, Stack, Typography } from '@mui/material';

import { userService } from '../../services/utils/axios';
import LoadingButton from '../core/LoadingButton';
import { useTranslation } from 'react-i18next';
import { validators } from '../../services/utils/validators';
const initialValues = {
  email: '',
  feedback: { title: '', detail: '' },
};

const Feedback = lazy(() => import('./Feedback'));

function ForgetPassword() {
  const { t } = useTranslation();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .max(255)
      .matches(validators.EMAIL, t('wrongEmail'))
      .email(t('wrongEmail'))
      .required(t('fillAllfields'))
      .label('E-Mail'),
  });
  /**
   * @method
   * @param {typeof initialValues} values - form values from formik
   * @param {import("formik").FormikHelpers<typeof initialValues>} actions
   */

  const handleSubmit = async (
    values,
    { setStatus, setErrors, setSubmitting }
  ) => {
    try {
      await userService.get(`/forget-password/${values.email}`);
      setStatus({ success: true, completed: true });
    } catch (error) {
      const message =
        error.error[0].errorCodeResponse == 'USER_NOT_FOUND'
          ? {
              title: t('accountNotexisteTitle'),
              detail: t('accountNotexisteBody'),
            }
          : error.error[0].errorCodeResponse == 'USER_NOT_VERIFIED'
            ? {
                title: t('accountNotActiveTitle'),
                detail: t('accountNotActiveBody'),
              }
            : {
                title: t('wrongInput'),
                detail: error.message || t('regNotsucc'),
              };
      setStatus({ success: false, completed: true });
      setErrors({ feedback: message });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, status, isSubmitting, setStatus }) => (
          <Form noValidate>
            <Suspense fallback={<div />}>
              <Feedback
                open={Boolean(status?.completed)}
                title={
                  status?.success
                    ? t('LBLRequestSent')
                    : errors?.feedback?.title || ''
                }
                severity={status?.success ? 'success' : 'warning'}
                message={
                  status?.success ? (
                    <>
                      <Typography>{t('LBLPasswordResetThanks')}</Typography>
                      <Typography>{t('LBLPasswordResetSuccess')}</Typography>
                    </>
                  ) : (
                    errors?.feedback?.detail || ''
                  )
                }
                handleClose={() => setStatus({ ...status, completed: false })}
              />
            </Suspense>
            <Stack justifyContent="center" alignItems="center">
              <Field
                component={TextField}
                name="email"
                label="E-Mail"
                margin="normal"
                sx={{ my: 10, maxWidth: 300 }}
                fullWidth
                required
              />
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                sx={{ px: 3, my: 4, borderRadius: 1 }}
                loading={isSubmitting}
              >
                {t('LBLPasswordReset')}
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
      <MuiLink
        component={Link}
        to="/login"
        color="text.primary"
        underline="always"
        fontWeight={500}
        align="center"
        sx={{ mt: 3, width: '100%', display: 'inline-block' }}
      >
        {t('LBLBackToLogin')}
      </MuiLink>
    </Container>
  );
}

export default ForgetPassword;
