import React, { lazy, Suspense, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import UserCreationForm from './UserCreationForm';
import UserCreationFormLocation from './UserCreationFormLocation';
import UserCreationFormContract from './UserCreationFormContract';
import UserCreationFormERPInitial from './UserCreationFormERPInitial';
import UserCreationFormERP from './UserCreationFormERP';

import ConfirmUser from './ConfirmUser';
import StepperPopup from '../../../../components/StepperPopup';
import { ArrowForward } from '@mui/icons-material';
import LogoLoader from '../../../../components/LogoLoader';
import { useTranslation, Trans } from 'react-i18next';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
const Feedback = lazy(() => import('../../../../components/Feedback'));
const FailFeedback = lazy(
  () => import('../../../../components/DataTable/Feedback')
);
/**
 * @param {PropTypes.InferProps<NutzerAnlegen.propTypes>} props
 */
const NutzerAnlegen = ({ open, handleClose, setOpen }) => {
  const { t } = useTranslation();
  const [payload, setPayload] = useState(null);
  const [payloadOne, setPayloadOne] = useState(null);
  const [payloadTwo, setPayloadTwo] = useState(null);
  const [payloadERP, setPayloadERP] = useState(null);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [authType, setAuthType] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [submit, setSubmit] = useState(0);
  const [submitOne, setSubmitOne] = useState(0);
  const [submitTwo, setSubmitTwo] = useState(0);
  const [submitERP, setSubmitERP] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const [canNextOne, setCanNextOne] = useState(false);
  const [canNextTwo, setCanNextTwo] = useState(false);
  const [canNextERP, setCanNextERP] = useState(false);
  const [confirmed, setConfirmed] = useState(0);
  const [rfidError, setrfidError] = useState(null);
  const [rfidSError, setrfidSError] = useState(null);
  const [emailError, setemailError] = useState(false);
  const [endCustomerId, setEndCustomerId] = useState(null);
  const [error, setError] = useState(false);
  const handleNext = () => setStep(step + 1);
  const handlePrev = () => setStep(step - 1);

  const openFeedback = () => {
    setAuthType(payloadOne?.authType);
    setPayload(null);
    setPayloadOne(null);
    setPayloadTwo(null);
    setPayloadERP(null);
    setCompleted(true);
  };
  const closeFeedback = () => {
    setPayload(null);
    setPayloadOne(null);
    setPayloadTwo(null);
    setPayloadERP(null);
    setCompleted(false);
    handleClose();
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onClose = (_event, reason) => {
    if (reason !== 'backdropClick') {
      handleClose();
      setPayload(null);

      setStep(0);
    }
  };

  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseOne = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadOne(null);

      setStep(1);
    }
  };

  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseTwo = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadTwo(null);

      setStep(2);
    }
  };
  /** @type {import("@mui/material").DialogProps['onClose']} */
  const onCloseERP = (_event, reason) => {
    if (reason !== 'backdropClick') {
      setPayloadERP(null);
      setStep(3);
    }
  };

  const handleSetPayload = (input) => {
    setPayload(input);
  };
  const handleSetPayloadOne = (input) => {
    setPayloadOne(input);
  };
  const handleNextevent = () => {
    handleNext();
  };
  const handleSetPayloadTwo = (input) => {
    setPayloadTwo(input);
  };
  const handleSetPayloadERP = (input) => {
    setPayloadERP(input);
  };
  useEffect(() => {
    setLoading(false);
  }, [completed, payloadOne, error, rfidError, rfidSError, emailError]);
  return (
    <>
      <LogoLoader loading={loading} />
      <StepperPopup
        widthInput="1000"
        title={t('LBLCreateUser')}
        isOpen={open}
        setIsOpen={setOpen}
        steps={[
          {
            label: t('enterUserinfo'),
            component: (
              <UserCreationForm
                setPayload={handleSetPayload}
                handleClose={onClose}
                confirmPayload={handleNext}
                payload={payload}
                setSubmit={setSubmit}
                setCanNext={setCanNext}
                submit={submit}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmit((prevState) => prevState + 1);
              },
              canNext: canNext,
            },
          },

          {
            label: t('authInfo'),
            component: (
              <UserCreationFormLocation
                setPayloadOne={handleSetPayloadOne}
                handleClose={onCloseOne}
                confirmPayload={handleNextevent}
                payload={payloadOne}
                setSubmit={setSubmitOne}
                setCanNext={setCanNextOne}
                submit={submitOne}
                editFlag={false}
                CreateUpdate="create"
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitOne((prevState) => prevState + 1);
              },
              canNext: canNextOne,
            },
          },
          {
            label: t('contractterms'),
            component: (
              <UserCreationFormContract
                setPayload={handleSetPayloadTwo}
                handleClose={onCloseERP}
                confirmPayload={handleNext}
                payload={payloadTwo}
                setSubmit={setSubmitTwo}
                setCanNext={setCanNextTwo}
                submit={submitTwo}
                editFlag={false}
                pdfFlag={false}
                cpoPaymentPreference={payloadOne?.cpoPaymentPreference}
              />
            ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitTwo((prevState) => prevState + 1);
              },
              canNext: canNextTwo,
            },
          },
          {
            label: t('otherinfo'),
            component:
              payloadTwo?.billingType != null ? (
                <UserCreationFormERP
                  setPayload={handleSetPayloadERP}
                  handleClose={onCloseTwo}
                  confirmPayload={handleNext}
                  payload={payloadERP}
                  billingTypeValue={payloadTwo?.billingType}
                  setSubmit={setSubmitERP}
                  setCanNext={setCanNextERP}
                  submit={submitERP}
                  cpoPaymentPreference={payloadOne?.cpoPaymentPreference}
                  editFlag={false}
                />
              ) : (
                <UserCreationFormERPInitial />
              ),
            nextAction: {
              label: t('LBLFurther'),
              icon: <ArrowForward />,
              action: () => {
                setSubmitERP((prevState) => prevState + 1);
              },
              canNext: canNextERP,
            },
          },

          {
            label: t('confirmInfo'),
            component: (
              <ConfirmUser
                payload={{
                  firstName: payload?.firstName,
                  lastName: payload?.lastName,
                  email: payload?.email,
                  contractAddress: payload?.contractAddress,
                  locationId: payloadOne?.locationId,
                  locationName: payloadOne?.locationName,
                  parkingLotNumbers: payloadOne?.parkingLotNumbers,
                  cpoId: payloadOne?.cpoId,
                  authType: payloadOne?.authType, // can be "APP" or "RFID"
                  billingType: payloadTwo?.billingType, // can be "WITH_BILLING" or "NO_BILLING"
                  rfidTags: payloadOne?.rfidTags,
                  rfidSTags: payloadOne?.rfidSTags,
                  contractInfo: payloadTwo?.contractInfo,
                  contractPaymentDetails: payloadERP?.contractPaymentDetails,
                  erpSystemParameters: payloadERP?.erpSystemParameters,
                  paymentMethod: payloadERP?.paymentMethod,
                  userType: payloadOne?.userType,
                }}
                handleClose={onClose}
                openFeedback={openFeedback}
                backToForm={handlePrev}
                setError={setError}
                setrfidError={setrfidError}
                setrfidSError={setrfidSError}
                setemailError={setemailError}
                setEndCustomerId={setEndCustomerId}
                submit={confirmed}
                setSubmit={setConfirmed}
                editFlag={false}
                contract={null}
              />
            ),
            nextAction: {
              label: t('LBLSave'),
              action: () => {
                setConfirmed((prevState) => prevState + 1);
                setOpen(false);
                setLoading(true);
              },
            },
          },
        ]}
      />
      <Suspense fallback={<div />}>
        <Feedback
          open={completed}
          title={authType === 'APP' ? t('newccreated') : t('newusercreated')}
          message={
            <>
              <Typography align="center">{t('thankforInput')}</Typography>

              <Typography align="center">
                {authType === 'APP' ? (
                  <>{t('newContdone')}</>
                ) : (
                  <>{t('newUserrfid')}.</>
                )}
              </Typography>
            </>
          }
          handleClose={closeFeedback}
        />
        <FailFeedback
          open={error}
          title={t('mistake')}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('LBLSomethingwentWrong')}
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setPayload(null);
            setPayloadOne(null);
            setPayloadTwo(null);
            setPayloadERP(null);
          }}
        />
        <FailFeedback
          open={emailError}
          title={t('emailinuse')}
          severity="warning"
          message={
            <>
              <Typography align="center">
                {t('emailinuser', { endCustomerId: endCustomerId })}
              </Typography>
            </>
          }
          handleClose={() => {
            setemailError(false);
            setPayload(null);
            setPayloadOne(null);
            setPayloadTwo(null);
            setPayloadERP(null);
          }}
        />
        <FailFeedback
          open={rfidError}
          title={t('rfidInuse')}
          severity="warning"
          message={
            <>
              {/* <Typography align="center">
                {t('rfidInuseBody')}
              </Typography> */}
              {/* response message is not coming parsed from BE so using static message for now   */}
              <Typography align="center">
                {t('rfiduserconnected')}:
                <Typography marginTop={3}>
                  <TableContainer component={Paper} variant="outlined">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>{t('LBLCustomerNum')}</TableCell>
                          <TableCell>
                            {rfidError &&
                            rfidError.some(
                              (val) =>
                                Array.isArray(val.serialId) &&
                                val.serialId[0]?.trim()
                            )
                              ? 'Serial Number'
                              : 'RFID Tags'}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rfidError?.map((val, key) => {
                          const serialId =
                            Array.isArray(val.serialId) &&
                            val.serialId[0]?.trim();
                          const rfIdTag =
                            Array.isArray(val.rfIdTag) && val.rfIdTag[0];
                          const displayValue = serialId || rfIdTag;

                          return (
                            <TableRow key={key}>
                              <TableCell>{val.endCustomerId}</TableCell>
                              <TableCell>{displayValue}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Typography>
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setPayload(null);
            setPayloadOne(null);
            setPayloadTwo(null);
            setPayloadERP(null);
            setrfidError(null);
            setrfidSError(null);
            setemailError(false);
          }}
        />

        <FailFeedback
          open={rfidSError}
          title={t('rfidSInuse')}
          severity="warning"
          message={
            <>
              <Typography align="center">
                <Trans
                  i18nKey="rfidSInuseBody"
                  values={{ errorDetails: rfidSError?.join(', ') }}
                  components={{ strong: <strong /> }}
                />
              </Typography>
            </>
          }
          handleClose={() => {
            setError(false);
            setPayload(null);
            setPayloadOne(null);
            setPayloadTwo(null);
            setPayloadERP(null);
            setrfidError(null);
            setrfidSError(null);
            setemailError(false);
          }}
        />
      </Suspense>
    </>
  );
};
NutzerAnlegen.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NutzerAnlegen;
